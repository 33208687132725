import { useHistory } from 'react-router-dom';
import { Button, Modal, Text } from '../../shared/components';
import { useStore } from '../../store-provider/use-store';

export const LearnMoreModal = ({ hideLearnMore }) => {
  const history = useHistory();
  const {
    data: { selectedBusiness },
  } = useStore();
  return (
    <Modal display={true} onClose={() => hideLearnMore()}>
      <Modal.Paper width="600px">
        <Modal.Header title="Brite Communication Tools" onClose={() => hideLearnMore()} />
        <Modal.Body>
          <Text body>
            Brite offers various options for distributing guides. You can send them via text, Slack, email, or
            distribute them using a QR code. By leveraging our communication tools located in the "Messaging" tab, we
            can improve analytics accuracy.
          </Text>
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={() => hideLearnMore()}>
            Back
          </Button>
          <Button primary onClick={() => history.push(`/${selectedBusiness?.ID}/messaging`)}>
            Explore
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
