export const fulfilledPromises = async (type, promisesObject = {}) => {
  const list = Object.entries(promisesObject);
  const promises = await Promise[type](list.map(([_, promise]) => promise));
  return list.reduce((acc, [key], index) => {
    if (promises[index].status === 'fulfilled') {
      acc[key] = promises[index]?.value;
    } else {
      acc[key] = null;
    }
    return acc;
  }, {});
};
