import { Box, Button, Input, Modal, Select, Text } from '../../shared/components';
import { Option } from '../../shared/components/select';
import { useForm } from '../../shared/use-form';

const layoutMapping = {
  DisplayValue: {
    editable: true,
    label: 'Field Label',
    type: 'input',
  },
  PropertyChain: {
    editable: false,
    label: 'Property Chain',
    type: 'input',
  },
  Type: {
    editable: true,
    label: 'Field Type',
    type: 'select',
    options: [
      'text-input',
      'text-area',
      'link',
      'number',
      'date',
      'dollar',
      'percent',
      'multipleOfSalary',
      'percentOfEmployeeElectedAmount',
      'toggle',
      'prescriptionDrugTier',
      'prescriptionDrugDeductible',
      'medicalPlanTypes',
      'dentalPlanTypes',
      'visionFrequency',
      'advancedInputField',
      'surestInputField',
      'networkType',
      'cost',
      'carrier',
    ],
  },
  State: {
    editable: true,
    label: 'State',
    type: 'select',
    options: ['show', 'hide', 'highlight'],
  },
};

export const EditLayoutField = ({ state, fieldProps, onClose }) => {
  const form = useForm(fieldProps?.field, []);
  const updateLayout = () => {
    fieldProps.updateLayout(form.values);
    onClose();
  };

  return (
    <Modal display={true} onClose={onClose}>
      <Modal.Paper>
        <Modal.Header title="Edit Field Layout" onClose={onClose} />
        <Modal.Body
          css={`
            display: grid;
            gap: 16px;
          `}
        >
          {Object.entries(form?.values || {})?.map(([property, item]) => (
            layoutMapping[property] && (<Box flex="space-between">
              <Text label>{layoutMapping[property].label}</Text>
              {layoutMapping[property].type === 'input' ? (
                <Input
                  {...form?.getProperty(property)}
                  disabled={!layoutMapping[property].editable}
                  css={`
                    width: 50%;
                  `}
                />
              ) : layoutMapping[property].type === 'select' ? (
                <Select
                  {...form?.getProperty(property)}
                  disabled={!layoutMapping[property].editable}
                  css={`
                    width: 50%;
                  `}
                >
                  {layoutMapping[property].options.map((option) => (
                    <Option value={option}>{option}</Option>
                  ))}
                </Select>
              ) : null}
            </Box>)
          ))}
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={updateLayout}>Update</Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
