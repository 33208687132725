import { CircularProgress, MenuItem } from '@material-ui/core';
import { css } from '@emotion/css';
import {
  ArrowSquareIn,
  Article,
  CaretLeft,
  CaretRight,
  DotsThree,
  ListBullets,
  Lock,
  PlusCircle,
  Square,
  Swatches,
  Trash,
} from 'phosphor-react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { CustomAxios } from '../../../redux/axios/axios';
import { Button, ConfirmationModal, Div, Divider, DropMenu, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { PagesModal } from '../modals/pages-modal';
import { SavePageTemplate } from '../modals/save-page-template';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { animation, borderRadius, flex, px } from '../../../shared/shared-styles';
import { NewBlankPageModal } from './new-blank-page-modal';
import { NewTemplatePageModal } from './new-template-page-modal';
import { useSearchParams } from '../../../shared/use-search-params';
import { PageSettings } from './page-settings';
import { BulkDelete } from '../modals/bulk-delete';
import { BriteLoader } from '../../../shared/components/brite-loader';
import { Dot } from '../../../shared/components/dot';
import { EditorDetailsContext } from '../../../BriteEditor/provider/editor-detail-provider';
import { needsAttention, useResource } from '../../../react-query';
import { Collapse } from '../../../common/components/Collapse';

const listContainer = css`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 300px;
  max-width: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px 16px;
  background-color: ${colors.gray[100]};
  resize: horizontal;
  user-select: none;
`;

const pageStyle = (selected) => css`
  height: 40px;
  background-color: ${colors.gray[100]};
  :hover {
    background-color: ${colors.gray[200]};
    .drop-menu-styles {
      display: block;
    }
  }
  ${selected ? `background-color: ${colors.gray[300]};` : '.drop-menu-styles { display: none; }'};
`;

export const PageList = ({
  pagesProps,
  pageId,
  setPageId,
  isVendor,
  course,
  courseLease,
  setEnrollmentPage,
  enrollmentPage,
}) => {
  const {
    query: { data: pages = [] },
    handleOptions,
    handlePageName,
    handleDuplicate,
    handleConvert,
    handleDelete,
    handleReOrder,
    handleLockPage,
    isLoading,
    refetch,
  } = pagesProps;

  const { editorDetails, setProperties } = useContext(EditorDetailsContext);

  const { params, updateParams } = useSearchParams();

  const hasBriteEditor = useFeatureFlagPayload('brite-editor');

  const initial = useRef(true);

  const collaborationFF = useFeatureFlagPayload('editor-collaboration')?.value;
  const showPDFDownloadFF = useFeatureFlagPayload('show-pdf-download')?.value;

  const needsAttentionResource = useResource(needsAttention, {
    enabled: false,
    search: {
      business_id: `eq.${course?.BusinessID}`,
      resource_id: `eq.${course?.ID}`,
    },
  });
  const comments = needsAttentionResource?.data || [];

  const validComments = comments?.reduce((prev, item) => {
    const { metadata, resolved } = item;
    const thread = comments.find(({ id }) => metadata.threadId === id);
    if (!resolved && !thread?.resolved) {
      return {
        ...prev,
        [metadata?.pageId]: [...(prev[metadata?.pageId] || []), item],
      };
    }
    return prev;
  }, {});

  const [renameId, setRenameId] = useState('');
  const [settingsId, setSettingsId] = useState('');
  const [duplicateId, setDuplicateId] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [loadingPreview, setLoadingPreview] = useState('');
  const [template, setTemplate] = useState(null);
  const [newPageType, setNewPageType] = useState('');
  const [modal, setModal] = useState('');

  const pageName = pages?.find(({ ID }) => ID === renameId)?.Name || '';

  useEffect(() => {
    if (initial && !!pages?.length && !pageId) {
      setPageId(pages?.[0]?.ID || '', true);
      initial.current = false;
    }
  }, [pages?.length]);

  const pageRoles = useMemo(() => {
    return pages?.reduce((prev, item) => {
      if (!!item?.Options?.Role) {
        const activePageId = item?.Active ? item?.ID : prev?.[item?.Options?.Role]?.activePageId || '';
        return {
          ...prev,
          [item?.Options?.Role]: {
            activePageId,
            pages: [...(prev?.[item?.Options?.Role]?.pages || []), item],
          },
        };
      }
      return prev;
    }, {});
  });

  const existingRoles = Object.keys(pageRoles || {});

  const reorderPages = ({ source, destination, draggableId }) => {
    if (!destination || (destination.index === source.index && destination.droppableId === source.droppableId)) {
      return;
    }
    let moveDown = false;
    if (source?.index < destination?.index) {
      moveDown = true;
    }

    if (courseLease.hasLease) {
      const filteredPages = pages?.filter(({ Active }) => Active);

      let destinationId = filteredPages[destination.index]?.ID || null;
      if (moveDown) {
        if (destination.index + 1 < filteredPages.length) {
          destinationId = filteredPages[destination.index + 1]?.ID;
        } else {
          destinationId = null;
        }
      }

      const activeSourcePage = pages?.find(({ ID }) => ID === draggableId);

      if (pageRoles[activeSourcePage?.Options?.Role]) {
        const sourcePages = pageRoles[activeSourcePage?.Options?.Role]?.pages;
        handleReOrder(sourcePages, destinationId);
      } else {
        handleReOrder([activeSourcePage], destinationId);
      }
    } else {
      toast.error(
        `You are currently unable to reorder pages, ${courseLease.leasedToEmail} is currently editing this guide.`
      );
    }
  };

  const viewUnlayerPDF = async (pageId) => {
    window?.unlayer?.exportHtml(async (data) => {
      try {
        setLoadingPreview(pageId);
        const response = await CustomAxios.post(`/v1/pages/${pageId}/pdf`, {
          Content: data.design,
        });
        window.open(response.data.url, '_blank');
      } catch (e) {
        console.log('Error previewing Unlayer PDF', e);
        toast.error('Error previewing Unlayer PDF');
      } finally {
        setLoadingPreview('');
      }
    });
  };

  const previewPDF = async (pageId) => {
    try {
      let apiUrl = `/v1/guide/${course.ID}/pdf/${pageId}`;

      if (course.Passcode) {
        apiUrl += '?passcode=' + course.Passcode;
      }

      const pdfPromise = CustomAxios.post(apiUrl, {}, { responseType: 'arraybuffer', timeout: 300000 });

      toast.promise(
        pdfPromise,
        {
          pending: 'Generating PDF for preview...',
          success: 'PDF generated successfully.\nCheck your tabs.',
          error: 'Unable to generate PDF for preview. Please try again later.',
        },
        {
          position: 'bottom-center',
        }
      );

      pdfPromise.then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        // Append parameters to hide the toolbar in the PDF viewer.
        const pdfViewerUrl = `${url}#toolbar=0`;

        // Opens in a new tab.
        const link = document.createElement('a');
        link.href = pdfViewerUrl;
        link.target = '_blank';
        link.click();
      });
    } catch (e) {
      console.error(e);
    }
  };

  const createPage = async (type) => {
    const sharedBody = {
      Type: type,
      FrontendID: '_' + Math.random().toString(36).substr(2, 9),
      IsLocked: false,
      TemplateID: '00000000-0000-0000-0000-000000000000',
      CourseID: course?.ID,
    };

    const body =
      type === 'question'
        ? { ...sharedBody, Name: 'Multi Choice', Question: null }
        : { ...sharedBody, Name: 'Decision Tool', InsurancePlans: [] };
    let pageId = '';
    try {
      toast.success(`Creating ${body?.Name} Page...`);
      const { data } = await CustomAxios.post(`v2/course/${course?.ID}/pages`, body);
      pageId = data?.ID;
    } catch (err) {
      toast.error(`Failed to create a new page.`);
      console.warn(err);
    } finally {
      refetch();
      if (pageId) {
        updateParams({ pageId });
      }
    }
  };

  const getPageErrorCount = (page) => {
    return Object.values(page?.RenderMetadata?.EvaluatedMergeTags || {}).filter(({ Success }) => !Success).length;
  };

  const togglePagesToolbar = () => {
    setProperties({ pagesToolbar: !editorDetails?.pagesToolbar });
  };

  const PageItem = ({ page, snapshot, isSubItem = false }) => {
    const role = page?.Options?.Role in pageRoles ? pageRoles[page?.Options?.Role] : {};

    if (!isSubItem && role?.activePageId) {
      return page?.Active ? (
        <Div
          css={css`
            ${snapshot?.isDraggingOver
              ? `
              border-radius: 8px;
              background-color: ${colors.gray[100]};
              box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
            `
              : ''}
          `}
        >
          <Div
            css={css`
              ${flex('space-between')}
              padding: 8px;
              border-radius: 8px;

              :hover {
                outline: 1px solid ${colors.gray[300]};
                border-radius: 8px;
              }
            `}
            onClick={() => setPageId(role?.activePageId)}
          >
            <Text label>{page?.Options?.Role}</Text>
            <Swatches />
          </Div>
          <Collapse isOpen={page?.Options?.Role in pageRoles && !snapshot?.isDraggingOver}>
            <Div
              css={css`
                margin-left: 4px;
                border-left: 4px solid ${colors.gray[300]};
                padding-left: 4px;
              `}
            >
              {role?.pages?.map((item) => (
                <PageItem page={item} isSubItem={true} />
              ))}
            </Div>
          </Collapse>
        </Div>
      ) : null;
    }

    return (
      <Div
        css={css`
          width: 100%;
          ${flex('left')}
          padding: 8px;
          border-radius: 8px;
          ${pageStyle(pageId === page.ID && enrollmentPage !== 'enroll')}
        `}
        onClick={() => {
          setPageId(page.ID);
        }}
      >
        {validComments[page?.ID]?.length && collaborationFF && params?.mode === 'collaborating' ? (
          <Text
            label
            css={css`
              border-radius: 30px;
              background-color: ${colors.purple};
              ${flex('center')}
              color: white;
              padding: 0 8px;
              min-width: 24px;
              min-height: 24px;
              margin-right: 8px;
            `}
            bold
          >
            {validComments[page?.ID]?.length || 0}
          </Text>
        ) : null}

        <Div
          styless="ml-sm fg"
          className={css`
            overflow: hidden;
            flex-grow: 1;
            ${flex('left')}
            ${(!role?.activePageId || role?.activePageId === page?.ID) && page?.Active
              ? ''
              : `
                opacity: .5;
            `}
          `}
        >
          <Text styles="label ellipsis">
            {page?.IsLocked ? <Lock size={24} /> : null}
            {page?.Name}
          </Text>
        </Div>
        {page.ID && page.ID !== loadingPreview ? (
          courseLease.hasLease && (
            <DropMenu
              listStyle={{
                minWidth: '128px',
                width: '200px',
              }}
              title=""
              button={
                <Button
                  styles="icon"
                  className={css`
                    width: 32px;
                    height: 32px;
                    padding: 0;
                  `}
                >
                  <DotsThree />
                </Button>
              }
            >
              <MenuItem style={{ padding: '8px' }} onClick={() => setRenameId(page.ID)}>
                <Text label>Rename</Text>
              </MenuItem>
              {(page.Type === 'regular' || page.Type === 'brite-editor') && (
                <>
                  <MenuItem style={{ padding: '8px' }} onClick={() => setDuplicateId(page.ID)}>
                    <Text label>Duplicate</Text>
                  </MenuItem>
                </>
              )}
              {page.Type === 'regular' && (
                <>
                  <MenuItem style={{ padding: '8px' }} disabled={page?.ID !== pageId} onClick={() => setTemplate(page)}>
                    <Text label>Save As Template</Text>
                  </MenuItem>
                  <MenuItem
                    style={{ padding: '8px' }}
                    disabled={page?.ID !== pageId}
                    onClick={() => viewUnlayerPDF(page.ID)}
                    className={
                      loadingPreview
                        ? css`
                            color: ${colors.gray[300]};
                          `
                        : ''
                    }
                  >
                    <Text label>PDF Preview</Text>
                  </MenuItem>
                  {hasBriteEditor?.value && (
                    <MenuItem
                      style={{ padding: '8px' }}
                      disabled={page?.ID !== pageId}
                      onClick={() => handleConvert(page.ID)}
                    >
                      <Text label>Copy to Brite Editor</Text>
                    </MenuItem>
                  )}
                </>
              )}
              {!page.IsLocked && page.Type === 'regular' && isVendor && (
                <MenuItem style={{ padding: '8px' }} onClick={() => handleLockPage(page.ID)}>
                  <Text label>Lock Page</Text>
                </MenuItem>
              )}
              {showPDFDownloadFF && page.Type === 'brite-editor' && (
                <MenuItem style={{ padding: '8px' }} onClick={() => previewPDF(page.ID)}>
                  <Text label>Preview Page PDF</Text>
                </MenuItem>
              )}
              <MenuItem style={{ padding: '8px' }} onClick={() => setSettingsId(page.ID)}>
                <Text label>Page Settings</Text>
              </MenuItem>
              <Divider />
              {pages.length > 1 && (
                <MenuItem style={{ padding: '8px', color: colors.red[100] }} onClick={() => setDeleteId(page.ID)}>
                  <Text label color={colors.red[100]}>
                    Delete
                  </Text>
                </MenuItem>
              )}
            </DropMenu>
          )
        ) : (
          <div
            className={css`
              width: 24px;
            `}
          >
            <CircularProgress size={24} />
          </div>
        )}
      </Div>
    );
  };

  return (
    <div
      className={css`
        position: relative;
        ${editorDetails?.pagesToolbar ? `max-width: 300px;` : 'max-width: 0;'}
        transition: max-width .3s ease;
      `}
    >
      <Div
        css={css`
          position: absolute;
          bottom: 34px;
          left: 100%;
          height: 40px;
          background-color: white;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border: 1px solid ${colors.gray[300]};
          z-index: 500;
          ${flex('center')}
          :not(:hover) {
            .hover-label {
              display: none;
              ${animation('fadeIn', '.3s ease')}
            }
          }
        `}
      >
        <Div
          className="hover-label"
          css={css`
            position: absolute;
            left: 0;
            bottom: calc(100% + 8px);
            background-color: ${colors.black};
            border-radius: 8px;
            padding: 8px;
            p {
              color: white;
              min-width: max-content;
            }
            strong {
              margin-left: 8px;
            }
          `}
        >
          <Text>
            Toggle Page List <strong>T</strong>
          </Text>
        </Div>
        <Button styles="icon sm" onClick={togglePagesToolbar}>
          {editorDetails?.pagesToolbar ? <CaretLeft size={24} /> : <CaretRight size={24} />}
        </Button>
      </Div>
      <Div css={listContainer}>
        <Div
          css={css`
            ${flex('space-between')}
            margin-bottom: ${px.md};
          `}
        >
          <Text
            styles="label bold"
            css={`
              flex-grow: 1;
            `}
          >
            Pages
          </Text>

          <DropMenu
            button={
              <Button styles="icon">
                <PlusCircle />
              </Button>
            }
          >
            <MenuItem onClick={() => setNewPageType('blank-page')}>
              <Square
                size={24}
                className={css`
                  margin-right: 8px;
                `}
              />
              Blank Page
            </MenuItem>
            <MenuItem onClick={() => setNewPageType('template-page')}>
              <Article
                size={24}
                className={css`
                  margin-right: 8px;
                `}
              />
              From Template
            </MenuItem>
            <MenuItem onClick={() => createPage('question')}>
              <ListBullets
                size={24}
                className={css`
                  margin-right: 8px;
                `}
              />
              Multi Choice
            </MenuItem>
            <MenuItem onClick={() => createPage('decision')}>
              <ArrowSquareIn
                size={24}
                className={css`
                  margin-right: 8px;
                `}
              />
              Decision Tool
            </MenuItem>
            <MenuItem onClick={() => setModal('bulk-delete')}>
              <Trash
                size={24}
                className={css`
                  margin-right: 8px;
                `}
              />
              Bulk Delete
            </MenuItem>
          </DropMenu>
        </Div>
        {isLoading ? (
          <BriteLoader
            size={64}
            weight={8}
            css={`
              ${flex('center start row')}
              padding-top: 15vh;
            `}
          />
        ) : (
          <DragDropContext onDragEnd={reorderPages}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {pages?.map((page, index) => (
                    <Draggable key={page.FrontendID} draggableId={page.ID || page?.FrontendID} index={index}>
                      {(provided) => (
                        <Div
                          key={page.ID}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={provided?.draggableProps?.style}
                        >
                          <Dot
                            display={getPageErrorCount(page) > 0}
                            hoverLabel={`${getPageErrorCount(page)} Errors`}
                            placement="top-left"
                            verticalSpacing={16}
                            horizontalSpacing={-6}
                            className={css`
                              width: 100%;
                            `}
                          >
                            <span style={{ display: 'none' }}>{provided.placeholder}</span>
                            <PageItem page={page} snapshot={snapshot} />
                          </Dot>
                        </Div>
                      )}
                    </Draggable>
                  ))}
                  <div
                    className={css`
                      ${snapshot.isDraggingOver && `display: none;`}
                    `}
                  >
                    <Div
                      className={css`
                        ${flex('jcsb aic')}
                        ${borderRadius.md}
                        margin-bottom: ${px.sm};
                        padding: 0 8px;
                        ${pageStyle(enrollmentPage === 'enroll')} :hover {
                          cursor: pointer;
                        }
                      `}
                      onClick={() => {
                        setEnrollmentPage('enroll');
                      }}
                    >
                      <Text
                        className={css`
                          color: #25282d;
                        `}
                      >
                        Enroll & Feedback
                      </Text>
                    </Div>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <NewBlankPageModal
          display={newPageType === 'blank-page'}
          onClose={() => setNewPageType('')}
          courseId={course?.ID}
          refetch={refetch}
        />
        <NewTemplatePageModal
          display={newPageType === 'template-page'}
          onClose={() => setNewPageType('')}
          courseId={course?.ID}
          refetch={refetch}
        />
        <PagesModal
          type="rename"
          data={pageName}
          display={renameId}
          onClose={() => setRenameId('')}
          onConfirm={(name) => handlePageName(renameId, name)}
          confirmText="Save Name"
          title="Rename Page"
        />
        <PagesModal
          type="duplicate"
          display={duplicateId}
          onClose={() => setDuplicateId('')}
          onConfirm={(name) => handleDuplicate(duplicateId, name)}
          confirmText="Duplicate"
          title="Duplicate Page"
        />
        <SavePageTemplate display={template !== null} close={() => setTemplate(null)} page={template} />
        <ConfirmationModal
          title="Are you sure you want to delete this page?"
          onConfirm={() => handleDelete([deleteId])}
          onClose={() => setDeleteId()}
          display={deleteId}
          isDeleteAlert
          confirmText="Delete Page"
        />

        {pages && settingsId && (
          <PageSettings
            display={settingsId}
            onClose={() => setSettingsId('')}
            existingRoles={existingRoles}
            pageOptions={pages?.find(({ ID }) => ID === settingsId)?.Options || {}}
            onSave={(opt) => handleOptions(settingsId, opt)}
          />
        )}

        <BulkDelete
          display={modal === 'bulk-delete'}
          onClose={() => setModal('')}
          handleDelete={handleDelete}
          pages={pages}
        />
      </Div>
    </div>
  );
};
