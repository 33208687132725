import { format, startOfDay } from 'date-fns';
import { css } from '@emotion/css';
import { groupBy, isEmpty } from 'lodash';
import { LineChart } from 'react-chartkick';
import { Tooltip } from '../../../common/components/Tooltip';
import { colors } from '../../../shared/styles';
import { EmptyState, EmptyStateSmall } from '../EmptyState';

const graphColors = [
  '#C613DB', // purple/pink
  '#F900D1', // pink
  '#FF2567', // pink/orange
  '#FF5C45', // orange
  '#FF8424', // orange/yellow
  '#FFA600', // yellow
];

export const ConversionTab = ({ analytics, startDate, endDate, getMinDate, getMaxDate, chartLegendOptions }) => {
  const getEnrollButtonClickedPercentage = (clicks) => {
    if (analytics.Activity && analytics.Activity.length && analytics.Activity.length > 0) {
      return Math.round((clicks / analytics.Activity.length) * 100);
    } else {
      return 0;
    }
  };

  const getDecisionToolCompletionsPercentage = (completions) => {
    if (analytics.Activity && analytics.Activity.length && analytics.Activity.length > 0) {
      return Math.round((completions / analytics.Activity.length) * 100);
    } else {
      return 0;
    }
  };

  const countEnrollmentClicksByDay = () => {
    var groups = groupBy(analytics.conversion.EnrollmentClicks, function (date) {
      return format(startOfDay(new Date(date.Timestamp)), 'yyyy-MM-dd');
    });
    let obj = {};

    const keys = Object.keys(groups);
    for (const key of keys) {
      obj[key] = groups[key].length;
    }
    return obj;
  };

  const countUniqueEnrollmentClicksByDay = () => {
    let userTracker = {};
    let uniqueDailyActivity = [];
    analytics.conversion.EnrollmentClicks.forEach((activity) => {
      const timestamp = format(startOfDay(new Date(activity.Timestamp)), 'yyyy-MM-dd');
      let uniqueKey = activity.UserID + String(timestamp);
      if (!userTracker[uniqueKey]) {
        userTracker[uniqueKey] = true;
        uniqueDailyActivity.push(activity);
      }
    });

    var groups = groupBy(uniqueDailyActivity, function (activity) {
      const timestamp = format(startOfDay(new Date(activity.Timestamp)), 'yyyy-MM-dd');
      return timestamp;
    });
    let obj = {};

    const keys = Object.keys(groups);
    for (const key of keys) {
      obj[key] = groups[key].length;
    }
    return obj;
  };

  const getTotalCount = () => {
    let count = analytics?.conversion?.DecisionToolCompletions || 0;
    return count;
  };

  const getEnrollmentClicksData = () => {
    return [
      {
        name: 'Total',
        data: countEnrollmentClicksByDay(),
        color: colors.black,
      },
      {
        name: 'Unique Visitors',
        data: countUniqueEnrollmentClicksByDay(),
        color: colors.purple,
      },
    ];
  };

  const getRecommendationPercentage = (recommendation, listKey) => {
    let totalCount = getTotalCount(listKey);
    if (listKey === 'Buyup') {
      return recommendation.Count / totalCount;
    }
    return recommendation.SuggestedCount / totalCount;
  };

  const calculateEnrollmentConfidence = () => {
    let count = 0;
    for (let confidence of analytics.enrollmentConfidence) {
      if (confidence.confident) {
        count += 1;
      }
    }
    if (analytics.enrollmentConfidence.length === 0) {
      return 0;
    }
    return Math.floor((count / analytics.enrollmentConfidence.length) * 100);
  };
  return (
    <>
      <div
        className={css`
          display: flex;
          margin-top: 40px;
          align-items: center;
        `}
      >
        <Tooltip
          title={`The total percentage of visitors that responded "Yes" in your guide when asked if they were confident to enroll in their benefits.`}
          placement={'top'}
        >
          <div
            className={css`
              flex: 1;
              background: #ffffff;
              border: 1px solid ${colors.gray[300]};
              border-radius: 8px;
              padding: 16px 24px;
              margin-right: 24px;
            `}
          >
            {!analytics.enrollmentConfidence.error && analytics.enrollmentConfidence.length > 0 ? (
              <>
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height, or 150% */
                    letter-spacing: 0.15px;
                    color: ${colors.gray[500]};
                  `}
                >
                  Enrollment Confidence
                </div>
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 34px;
                    line-height: 48px;
                    /* identical to box height, or 141% */
                    letter-spacing: 0.25px;
                    color: ${colors.black};
                    display: flex;
                    align-items: center;
                    gap: 12px;
                  `}
                >
                  <div>{analytics.enrollmentConfidence.error ? 0 : calculateEnrollmentConfidence()}%</div>

                  <div
                    className={css`
                      color: ${colors.gray[500]};
                      font-family: Roboto;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 24px;
                      letter-spacing: 0.15px;
                    `}
                  >
                    (
                    {`${analytics.enrollmentConfidence.error ? 0 : analytics.enrollmentConfidence.length} ${
                      analytics.enrollmentConfidence.length === 1 ? 'Response' : 'Responses'
                    }`}
                    )
                  </div>
                </div>
              </>
            ) : (
              <EmptyStateSmall />
            )}
          </div>
        </Tooltip>
        <Tooltip title="The total number of visitors that clicked an enrollment button on your guide" placement={'top'}>
          <div
            className={css`
              flex: 1;
              background: #ffffff;
              border: 1px solid ${colors.gray[300]};
              border-radius: 8px;
              padding: 16px 24px;
              margin-right: 24px;
            `}
          >
            {' '}
            {analytics?.conversion?.EnrollmentClicks?.length > 0 ? (
              <>
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height, or 150% */
                    letter-spacing: 0.15px;
                    color: ${colors.gray[500]};
                  `}
                >
                  Enrollment Button Clicks
                </div>
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 34px;
                    line-height: 48px;
                    /* identical to box height, or 141% */
                    letter-spacing: 0.25px;
                    color: ${colors.black};
                    display: flex;
                    align-items: center;
                    gap: 12px;
                  `}
                >
                  <div>{analytics?.conversion?.EnrollmentClicks?.length.toLocaleString() || 0}</div>
                  <div
                    className={css`
                      color: ${colors.gray[500]};
                      font-family: Roboto;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 24px;
                      letter-spacing: 0.15px;
                    `}
                  >
                    ({getEnrollButtonClickedPercentage(analytics?.conversion?.EnrollmentClicks?.length || 0)}
                    %)
                  </div>
                </div>
              </>
            ) : (
              <EmptyStateSmall />
            )}
          </div>
        </Tooltip>
        <Tooltip
          title="The total number of visitors that completed the decision tool at any point on your guide"
          placement={'top'}
        >
          <div
            className={css`
              flex: 1;
              background: #ffffff;
              border: 1px solid ${colors.gray[300]};
              border-radius: 8px;
              padding: 16px 24px;
            `}
          >
            {analytics?.conversion?.DecisionToolCompletions > 0 ? (
              <>
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height, or 150% */

                    letter-spacing: 0.15px;

                    color: ${colors.gray[500]};
                  `}
                >
                  Decision Tool Completions
                </div>
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 34px;
                    line-height: 48px;
                    /* identical to box height, or 141% */
                    letter-spacing: 0.25px;
                    color: ${colors.black};
                    display: flex;
                    align-items: center;
                    gap: 12px;
                  `}
                >
                  <div>{analytics?.conversion?.DecisionToolCompletions.toLocaleString() || 0}</div>
                  <div
                    className={css`
                      color: ${colors.gray[500]};
                      font-family: Roboto;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 24px;
                      letter-spacing: 0.15px;
                    `}
                  >
                    ({getDecisionToolCompletionsPercentage(analytics?.conversion?.DecisionToolCompletions || 0)}
                    %)
                  </div>
                </div>
              </>
            ) : (
              <EmptyStateSmall />
            )}
          </div>
        </Tooltip>
      </div>
      <div
        className={css`
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          /* identical to box height, or 133% */
          color: ${colors.black};
          margin-top: 40px;
        `}
      >
        Enrollment Clicks by Day
      </div>
      <div
        className={css`
          border-radius: 8px;
          border: 1px solid ${colors.gray[300]};
          padding: 24px;
          margin-top: 16px;
        `}
      >
        {!isEmpty(countEnrollmentClicksByDay()) || !isEmpty(countUniqueEnrollmentClicksByDay()) ? (
          <LineChart
            adapter="chartjs"
            messages={{ empty: 'No data' }}
            xmin={startDate ? format(startDate, 'yyyy-MM-dd') : getMinDate('yyyy-MM-dd')}
            xmax={endDate ? format(endDate, 'yyyy-MM-dd') : getMaxDate('yyyy-MM-dd')}
            min={0}
            max={countEnrollmentClicksByDay().length}
            scales
            library={{
              plugins: {
                legend: chartLegendOptions,
              },
            }}
            data={getEnrollmentClicksData()}
          />
        ) : (
          <EmptyState />
        )}
      </div>
      <div
        className={css`
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          /* identical to box height, or 133% */
          color: ${colors.black};
          margin-top: 40px;
          margin-bottom: 24px;
        `}
      >
        Products Recommended
      </div>
      <div
        className={css`
          border: 1px solid ${colors.gray[300]};
          border-radius: 8px;
          padding: 32px;
        `}
      >
        {(analytics.conversion.MedicalRecommendations && analytics.conversion.MedicalRecommendations.length > 0) ||
        (analytics.conversion.NonMedicalRecommendations &&
          analytics.conversion.NonMedicalRecommendations.length > 0) ? (
          <>
            {analytics.conversion.MedicalRecommendations && analytics.conversion.MedicalRecommendations.length > 0 && (
              <div
                className={css`
                  color: ${colors.black};
                  font-feature-settings: 'clig' off, 'liga' off;
                  /* H4 */
                  font-family: Roboto;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 24px; /* 133.333% */
                  letter-spacing: 0.15px;
                  margin-bottom: 16px;
                `}
              >
                Medical Plans
              </div>
            )}
            {analytics.conversion?.MedicalRecommendations?.sort((a, b) => b.SuggestedCount - a.SuggestedCount).map(
              (recommendation) => {
                return (
                  <div
                    className={css`
                      color: ${colors.black};
                      font-feature-settings: 'clig' off, 'liga' off;
                      /* Label */
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 24px; /* 171.429% */
                      letter-spacing: 0.15px;
                      margin-bottom: 24px;
                    `}
                    key={recommendation.PlanName}
                  >
                    <div
                      className={css`
                        display: flex;
                        justify-content: space-between;
                      `}
                    >
                      <div>{recommendation.PlanName}</div>
                      <div
                        className={css`
                          color: ${colors.gray[500]};
                          font-feature-settings: 'clig' off, 'liga' off;
                          font-family: Roboto;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 24px; /* 171.429% */
                          letter-spacing: 0.15px;
                        `}
                      >
                        {Math.floor(getRecommendationPercentage(recommendation, 'Recommendation') * 100)}%
                      </div>
                    </div>
                    <Tooltip title={recommendation.SuggestedCount}>
                      <div
                        className={css`
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: flex-start;
                          align-self: stretch;
                          border-radius: 8px;
                          background: ${colors.gray[200]};
                          margin-top: 4px;
                          margin-bottom: 8px;
                        `}
                      >
                        <div
                          className={css`
                            width: ${getRecommendationPercentage(recommendation, 'Recommendation') * 100}%;
                            height: 8px;
                            border-radius: 8px;
                            background: #6d44fb;
                          `}
                        ></div>
                      </div>
                    </Tooltip>
                  </div>
                );
              }
            )}
            {analytics.conversion.NonMedicalRecommendations &&
              analytics.conversion.NonMedicalRecommendations.length > 0 && (
                <div
                  className={css`
                    color: ${colors.black};
                    font-feature-settings: 'clig' off, 'liga' off;
                    /* H4 */
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px; /* 133.333% */
                    letter-spacing: 0.15px;
                    margin-bottom: 16px;
                    padding-top: 24px;
                    margin-top: 24px;
                    border-top: 1px solid ${colors.gray[300]};
                  `}
                >
                  Other
                </div>
              )}
            {analytics.conversion.NonMedicalRecommendations.sort((a, b) => b.Count - a.Count).map(
              (recommendation, index) => {
                return (
                  <div
                    className={css`
                      color: ${colors.black};
                      font-feature-settings: 'clig' off, 'liga' off;
                      /* Label */
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 24px; /* 171.429% */
                      letter-spacing: 0.15px;
                      margin-bottom: 24px;
                    `}
                    key={recommendation.DisplayValue}
                  >
                    <div
                      className={css`
                        display: flex;
                        justify-content: space-between;
                      `}
                    >
                      <div>{recommendation.DisplayValue}</div>
                      <div
                        className={css`
                          color: ${colors.gray[500]};
                          font-feature-settings: 'clig' off, 'liga' off;
                          font-family: Roboto;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 24px; /* 171.429% */
                          letter-spacing: 0.15px;
                        `}
                      >
                        {Math.floor(getRecommendationPercentage(recommendation, 'Buyup') * 100)}%
                      </div>
                    </div>
                    <Tooltip title={recommendation.Count}>
                      <div
                        className={css`
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: flex-start;
                          align-self: stretch;
                          border-radius: 8px;
                          background: ${colors.gray[200]};
                          margin-top: 4px;
                          margin-bottom: 8px;
                        `}
                      >
                        <div
                          className={css`
                            width: ${getRecommendationPercentage(recommendation, 'Buyup') * 100}%;
                            height: 8px;
                            border-radius: 8px;
                            background: ${index < 5 ? graphColors[index] : colors.black};
                          `}
                        ></div>
                      </div>
                    </Tooltip>
                  </div>
                );
              }
            )}
          </>
        ) : (
          <EmptyState />
        )}
      </div>
    </>
  );
};
