import { css } from '@emotion/css';
import { Text } from '../../shared/components/text';

export const CourseTime = ({ timeInSeconds }) => {
  const h = Math.floor(timeInSeconds / 3600);
  const m = Math.floor((timeInSeconds % 3600) / 60);
  const s = Math.floor((timeInSeconds % 3600) % 60);

  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        gap: 12px;
      `}
    >
      {h > 0 && (
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          <Text h1>{h}</Text>
          <Text body>Hrs</Text>
        </div>
      )}
      <div
        className={css`
          display: flex;
          align-items: center;
          gap: 4px;
        `}
      >
        <Text h1>{m}</Text>
        <Text body>Min</Text>
      </div>
      {h <= 0 && (
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          <Text h1>{s}</Text>
          <Text body>Secs</Text>
        </div>
      )}
    </div>
  );
};
