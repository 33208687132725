import { MenuItem } from '@material-ui/core';
import { useState } from 'react';
import { css } from '@emotion/css';
import {
  Asterisk,
  X,
  DotsThreeVertical,
  EyeSlash,
  Flag,
  ListPlus,
  HighlighterCircle,
  EyeClosed,
  Eye,
  BookmarkSimple,
} from 'phosphor-react';
import { Button, Div, Input, Select, DropMenu, Icon } from '../../shared/components';
import { container, flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { useForm } from '../../shared/use-form';
import { useStateSync } from '../../shared/use-state-sync';
import { getFieldProps } from './field-utils';
import { EditFieldLayout } from './edit-field-layout';
import { textTheme } from '../../shared/components/text';
import { needsAttention } from '../../react-query/resources/needs-attention';
import { useSearchParams } from '../../shared/use-search-params';
import { ReactComponent as BookmarkSlash } from '../../images/svg/bookmark-slash.svg';
import { toast } from 'react-toastify';
import { useRequests } from '../../react-query';
import { Collapse } from '../../common/components/Collapse';

const pendStyle = css`
  width: 100%;
  border: none;
  padding: 0 8px;
  input {
    font-size: 1em;
    color: ${colors.gray[500]};
  }
  :hover {
    border: none;
  }
`;

const childContainer = ({ hideBorder, hasAdditionalOptions, hasChanges, config }) => css`
  width: 100%;
  margin: 8px 0;
  max-width: calc(100% - 40px);
  border-radius: 8px;
  ${!hideBorder
    ? `
    border: 1px solid ${colors.gray[300]};
    border-radius: 8px;
    :hover {
      border: 1px solid ${colors.black};
    }
    :focus-within {
      border: 1px solid black;
      outline: 1px solid black;
    }
    ${
      config?.disabled
        ? `
      background-color: ${colors.gray[100]};
      color: ${colors.gray[200]};
      :hover {
        border: 1px solid ${colors.gray[300]};
      }
    `
        : ''
    }
  `
    : ''}
  .layout-item {
    border: none;
    padding-left: 0;
    padding-right: 0;
    :hover {
      border: none;
    }
  }
  ${hasAdditionalOptions
    ? `
      border: 1px solid ${hasChanges ? colors.purple : colors.gray[300]};
      border-radius: 8px;
      :hover {
        border: 1px solid ${hasChanges ? colors.purple : colors.black};
      }
    `
    : ''}
`;

export const LayoutContainer = ({
  state,
  field,
  config,
  isSectionSyncing = false,
  hideBorder = false,
  hideDividers = false,
  children,
  hideFlag,
}) => {
  const { updateLayout } = getFieldProps(state, field, config);

  const { values, getProperty, hasChanges } = useForm(field, [field]);
  const setFormFields = () => updateLayout(values);

  const displayState = useStateSync(
    {
      Pre: !!field?.Pre,
      Post: !!field?.Post,
    },
    [field?.Pre, field?.Post]
  );
  const [options, setOptions] = displayState;

  const hasAdditionalOptions = !!options?.Pre || !!options?.Post;

  const removeOption = (key) => {
    setOptions({ ...options, [key]: false });
    updateLayout({ [key]: '' });
  };

  const handleFormat = (e) => {
    const format = e.target.value;
    updateLayout({ Type: format });
  };

  const formatOptions = Object.entries(config?.formatOptions || {});

  return (
    <Div
      css={css`
        ${flex('left')}
        width: 100%;
      `}
    >
      <Div
        css={childContainer({
          hideBorder,
          hasAdditionalOptions,
          hasChanges,
          config,
        })}
        className="suggested-field"
      >
        <Collapse isOpen={options.Pre}>
          <Input
            {...getProperty('Pre')}
            data-field={true}
            onBlur={setFormFields}
            startIcon={<Asterisk />}
            endIcon={
              <Button
                styles="icon sm"
                onClick={() => removeOption('Pre')}
                css={`
                  min-width: 32px;
                `}
              >
                <X />
              </Button>
            }
            css={pendStyle}
          />
        </Collapse>
        <Div
          css={css`
            ${config?.layoutAlignment === 'vertical'
              ? `
                ${flex('start column column-reverse')}
                
              `
              : flex('left')}
            width: 100%;
            .layout-select {
              ${config?.layoutAlignment === 'vertical'
                ? `
                width: calc(100% - 16px);
                margin: 0;
                border-bottom: 1px solid ${colors.gray[300]};
                `
                : ''}
            }
            ${hasAdditionalOptions
              ? `
              ${options.Pre && !hideDividers ? `border-top: 1px solid ${colors.gray[200]}` : ''};
              ${options.Post && !hideDividers ? `border-bottom: 1px solid ${colors.gray[200]}` : ''};
              margin: 0;
              width: calc(100% - 16px);
            `
              : ''}
          `}
        >
          {children}
          {formatOptions?.length ? (
            <LayoutSelect value={field?.Type} onChange={handleFormat} alignment={config?.layoutAlignment}>
              {formatOptions?.map(([key, display]) => (
                <MenuItem value={key}>{display || key}</MenuItem>
              ))}
            </LayoutSelect>
          ) : null}
        </Div>
        <Collapse isOpen={options.Post}>
          <Input
            {...getProperty('Post')}
            data-field={true}
            onBlur={setFormFields}
            startIcon={<Asterisk />}
            endIcon={
              <Button
                styles="icon sm"
                onClick={() => removeOption('Post')}
                css={`
                  min-width: 32px;
                `}
              >
                <X />
              </Button>
            }
            css={pendStyle}
          />
        </Collapse>
      </Div>{' '}
      {field?.Type === 'label' ? null : (
        <EditLayoutButton
          isSectionSyncing={isSectionSyncing}
          state={state}
          field={field}
          config={config}
          updateLayout={updateLayout}
          displayState={displayState}
          hideFlag={hideFlag}
        />
      )}{' '}
    </Div>
  );
};

export const LayoutSelect = ({ value, onChange, children, disabled, alignment = '' }) => {
  return (
    <Div
      className="layout-select"
      css={css`
        ${flex('left')}
        box-sizing: border-box;
      `}
    >
      {alignment !== 'vertical' ? (
        <Div
          css={css`
            border-left: 1px solid ${colors.gray[300]};
            margin-top: 8px;
            margin-bottom: 8px;
            height: 24px;
          `}
        />
      ) : null}
      <Select
        disabled={disabled}
        value={value}
        onChange={onChange}
        css={`
          ${disabled ? `background-color: transparent;` : ''}
          width: 100%;
          .MuiOutlinedInput-notchedOutline {
            :active,
            :hover {
              outline: none;
              border: none;
            }
            border: none;
          }
        `}
      >
        {children}
      </Select>
    </Div>
  );
};

const menuItem = css`
  ${textTheme.label}
  ${container.hover}
  padding: 8px 16px;
  border-radius: 8px;
`;

export const EditLayoutButton = ({
  state,
  field,
  updateLayout,
  displayState = [],
  config,
  hidden,
  hideFlag = false,
  isSectionSyncing,
}) => {
  const { updateParams } = useSearchParams();

  const [show, setShow] = displayState;
  const [modal, setModal] = useState('');
  const getIsHidden = (state) => hidden?.includes(state);

  const flag = needsAttention.utils.getFlagByPropertyChain(state?.flags, field?.PropertyChain);

  const flagRequests = useRequests(state?.flags);

  const toggleFlag = async () => {
    if (flag) {
      try {
        const deleteFlag = needsAttention.utils.getDelete(flag?.id, state?.flags?.params);
        await flagRequests.delete(deleteFlag);
      } catch {
        toast.error(`Unable to remove flag.`);
      }
    } else {
      updateParams({
        editFlagId: flag?.id || '',
        flagProperty: field?.PropertyChain,
      });
    }
  };

  return (
    <>
      <DropMenu
        anchorOrigin={{ horizontal: 'right' }}
        transformOrigin={{ horizontal: 160 }}
        button={
          <Button
            disabled={isSectionSyncing}
            styles="icon sm"
            css={`
              margin: 8px;
              margin-right: 0;
            `}
          >
            {!config?.hideEditLayout && field?.State === 'hide' ? (
              <EyeSlash size={24} />
            ) : !config?.hideEditLayout && field?.State === 'highlight' ? (
              <Flag size={24} />
            ) : (
              <DotsThreeVertical size={24} />
            )}
          </Button>
        }
      >
        {!config?.hideEditLayout && !getIsHidden('hide') && field?.State !== 'hide' ? (
          <div
            className={menuItem}
            onClick={() => updateLayout({ State: 'hide' })}
            disabled={field?.State === 'highlight' || field?.State === 'hide'}
          >
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                gap: 32px;
              `}
            >
              <div>Hide Field</div>
              <div>
                <EyeClosed size={24} />
              </div>
            </div>
          </div>
        ) : null}

        {!config?.hideEditLayout && !getIsHidden('show') && field?.State !== 'show' ? (
          <div
            className={menuItem}
            onClick={() => updateLayout({ State: 'show' })}
            disabled={field?.State === 'highlight' || field?.State === 'show'}
          >
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                gap: 32px;
              `}
            >
              <div>{field?.State === 'highlight' ? 'Remove Highlight' : 'Show Field'}</div>
              <div>
                <Eye size={24} />
              </div>
            </div>
          </div>
        ) : null}

        {!config?.hideEditLayout &&
        !getIsHidden('highlight') &&
        field?.State !== 'highlight' &&
        field?.State === 'show' ? (
          <div
            className={menuItem}
            onClick={() =>
              updateLayout({
                State: field?.State === 'highlight' ? 'show' : 'highlight',
              })
            }
            disabled={field?.State === 'hide'}
          >
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                gap: 32px;
              `}
            >
              <div> {field?.State === 'highlight' ? 'Remove Highlight' : 'Highlight Field'}</div>
              <div>
                <HighlighterCircle size={24} />
              </div>
            </div>
          </div>
        ) : null}

        {!hideFlag && !getIsHidden('flag') ? (
          <div className={menuItem} onClick={toggleFlag}>
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                gap: 32px;
              `}
            >
              <div> {flag ? 'Remove Flag' : 'Flag Field'}</div>
              <div>{flag ? <Icon SVG={BookmarkSlash} size={24} /> : <BookmarkSimple size={24} />}</div>
            </div>
          </div>
        ) : null}

        {!config?.hideEditLayout && !!displayState.length ? (
          <>
            <div className={menuItem} onClick={() => setShow((o) => ({ ...o, Pre: true }))} disabled={show.Pre}>
              <div
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  gap: 32px;
                `}
              >
                <div>Add Pre Text</div>
                <div
                  className={css`
                    transform: rotate(180deg);
                  `}
                >
                  <ListPlus size={24} />
                </div>
              </div>
            </div>
            <div className={menuItem} onClick={() => setShow((o) => ({ ...o, Post: true }))} disabled={show.Post}>
              <div
                className={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  gap: 32px;
                `}
              >
                <div>Add Post Text</div>
                <div>
                  <ListPlus size={24} />
                </div>
              </div>
            </div>
          </>
        ) : null}
      </DropMenu>
      {modal === 'edit-field' ? (
        <EditFieldLayout
          display={modal === 'edit-field'}
          onClose={() => setModal('')}
          field={field}
          updateFieldLayout={updateLayout}
        />
      ) : null}
    </>
  );
};
