import { css } from '@emotion/css';
import React, { forwardRef } from 'react';
import { animation } from '../shared-styles';
import { X } from 'phosphor-react';
import { Button } from './button';
import { Text } from './text';
import { Box } from './box';
import { Portal } from './Portal';
import { useEvent } from '../use-event';

export const Modal = forwardRef(
  (
    {
      display,
      onClose,
      children,
      css: cssString = '',
      full = false,
      zIndex = '1000',
      stopPropagation = false,
      ...rest
    },
    ref
  ) => {
    const stopPropagationEvent = (e) => {
      if (stopPropagation) {
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    };

    useEvent('mouseup', stopPropagationEvent, { capture: true });
    useEvent('touchstart', stopPropagationEvent, { capture: true });

    if (!display) {
      return null;
    }

    return (
      <Portal>
        <Box
          ref={ref}
          onClick={(e) => {
            e.stopPropagation();
            onClose?.();
          }}
          full={full}
          {...rest}
          css={`
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: ${zIndex};
            background-color: rgba(0, 0, 0, 0.4);
            ${animation('fadeIn', '.2s ease')}
          `}
          flex="center"
        >
          <Box
            css={`
              background-color: white;
              ${full
                ? `
                height: 100vh;
                width: 100vw;
            `
                : `border-radius: 16px;`}
              ${cssString}
            `}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </Box>
        </Box>
      </Portal>
    );
  }
);

Modal.Paper = ({ width = '500px', children, css: cssString, ...rest }) => (
  <div
    className={css`
      width: ${width};
      padding: 32px;
      ${cssString}
    `}
    {...rest}
  >
    {children}
  </div>
);

Modal.Header = ({ title, onClose, css: cssString }) => {
  const headerTitle = typeof title === 'string' ? <Text h2>{title}</Text> : title;
  return (
    <Box
      flex="space-between"
      className={css`
        width: 100%;
        padding-bottom: 8px;
        ${cssString}
      `}
    >
      {headerTitle}
      <Button styles="icon" onClick={onClose}>
        <X />
      </Button>
    </Box>
  );
};

Modal.Body = ({ children, css: cssString }) => (
  <div
    className={css`
      width: 100%;
      padding: 8px 0;
      ${cssString}
    `}
  >
    {children}
  </div>
);

Modal.Actions = ({ children, css: cssString, flex = 'right' }) => (
  <Box
    flex={flex}
    className={css`
      width: 100%;
      padding-top: 16px;
      button {
        margin-left: 16px;
      }
      ${cssString}
    `}
  >
    {children}
  </Box>
);
