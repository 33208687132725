import { CurrencyDollarSimple, Percent } from 'phosphor-react';
import { useEffect, useRef } from 'react';
import { Input } from '../../shared/components';
import { colors } from '../../shared/styles';
import { useStateSync } from '../../shared/use-state-sync';
import { useStore } from '../../store-provider/use-store';
import { displayFormatMap, saveFormatMap } from './field-utils';
import { useAcls } from '../../shared/use-acls';
import { WRITE_BENEFITS_PACKAGE } from '../../shared/acl-constants';

const key = 'copy-field-value';
const activeKey = 'active-data-field';

const nextElement = () => {
  const query = 'input:not([disabled])';
  const focussable = Array.prototype.filter.call(document.querySelectorAll(query), (element) => {
    const indexable = element.getAttribute('tabindex') !== '-1';
    return indexable && (element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement);
  });
  const index = focussable.indexOf(document.activeElement);
  if (index > -1) {
    const nextElement = focussable[index + 1] || focussable[0];
    return nextElement;
  }
};

const defaultFieldLayout = {
  PropertyChain: '',
  Type: 'text-input',
  productId: '',
};

const defaultFieldConfig = {
  roundUp: true,
  storeValueAs: 'string',
  disabled: false,
};

// >> saveFormatMap <<
// Defines how fields should be formatted:
// ||  This allows for users to input however they want and
// ||  still account for errors and maintain consistency among
// ||  the different field types
export const getStoredValue = (field, config, value) => {
  let nextValue = value;
  if (field?.Type in saveFormatMap) {
    nextValue = saveFormatMap[field?.Type](value, {
      isNumber: config?.storeValueAs === 'number',
    });
  }
  return nextValue;
};

export const FieldInput = (props) => {
  const { updateProperty, handlePasteValue = null, field = {}, config = {}, ...rest } = props;

  const canEdit = useAcls([WRITE_BENEFITS_PACKAGE]);
  config.disabled = config.disabled || !canEdit;

  const fieldLayout = {
    ...defaultFieldLayout,
    ...field,
  };

  const fieldConfig = {
    ...defaultFieldConfig,
    ...config,
  };

  const decimals = fieldConfig?.roundUp ? 0 : 2;
  const inputRef = useRef(null);
  const { data, setStore } = useStore();

  const getStateValue = (value) => {
    if (fieldLayout?.Type in displayFormatMap) {
      return displayFormatMap[fieldLayout?.Type](value, { decimals });
    }
    return value;
  };

  const [value, setValue] = useStateSync(
    () => getStateValue(rest.value),
    [fieldLayout?.productId, fieldLayout?.Type, decimals, rest.value]
  );

  const getNextField = () => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
      const next = nextElement();
      let resetActive = true;
      if (next) {
        next.focus();
        if (next.hasAttribute('data-field')) {
          setStore(activeKey, next);
          resetActive = false;
        }
      }
      if (resetActive) {
        setStore(activeKey, null);
      }
    }, 10);
  };

  const syncValues = (value) => {
    const stateValue = getStateValue(value);
    setValue(stateValue);
    const nextValue = getStoredValue(fieldLayout, fieldConfig, stateValue);
    updateProperty(fieldLayout?.PropertyChain, nextValue);
  };

  const onFocus = (event) => event.target.select();

  const isFocusedElement = data.selectedSpreadsheetId && inputRef.current === data[activeKey];

  const onBlur = (event) => {
    syncValues(value);
    if (rest.onBlur) {
      rest.onBlur(event);
    }
  };

  const onClick = () => setStore(activeKey, inputRef.current);

  useEffect(() => {
    if (isFocusedElement && data[key]) {
      if (handlePasteValue) {
        handlePasteValue(fieldLayout, data[key]);
      } else {
        syncValues(data[key]);
      }
      getNextField();
      setStore(key, null);
    }
  }, [data[key]]);

  useEffect(() => {
    setValue(getStateValue(rest.value));
  }, []);

  const defaultIcons =
    fieldLayout?.Type === 'dollar'
      ? { startIcon: <CurrencyDollarSimple /> }
      : fieldLayout?.Type === 'percent' || fieldLayout?.Type === 'percentOfEmployeeElectedAmount'
      ? { endIcon: <Percent /> }
      : {};

  // All input fields should be type="text" so we can properly format them.
  // Date is special, for obvious reasons.
  return (
    <Input
      type={fieldLayout?.Type === 'date' ? 'date' : 'text'}
      ref={inputRef}
      onFocus={onFocus}
      name={fieldLayout?.PropertyChain}
      {...defaultIcons}
      disabled={fieldConfig?.disabled}
      {...rest}
      value={value}
      onClick={onClick}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      onBlur={onBlur}
      css={`
        width: 100%;
        ${isFocusedElement ? `outline: 2px solid ${colors.purple}; outline-offset: -1px;` : ''}

        color: ${colors.gray[100]};
      `}
      className={'layout-item ' + rest.className}
      data-field={true}
    />
  );
};
