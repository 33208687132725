import { DotsThree, StarFour, UploadSimple } from 'phosphor-react';
import { Box, BriteLoader, Button, Dropdown, Modal, Text } from '../../shared/components';
import { colors } from '../../shared/styles';
import { css } from '@emotion/css';
import { useDropzone } from 'react-dropzone';
import { useCallback, useRef, useState } from 'react';
import { CustomAxios } from '../../redux/axios/axios';
import { fileTypesMap } from '../../Documents/FullscreenUploader';
import { toast } from 'react-toastify';
import { Tooltip } from '../../common/components/Tooltip';
import { ExtensionIconMapper } from '../../Documents/ExtensionIconMapper';
import { ReactComponent as AiFileGradient } from '../../images/svg/ai-file-gradient.svg';

export const UploadMediaDropdown = ({ packageId, media, refetchMedia }) => {
  const link = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const closeModal = () => {
    setFiles([]);
    refetchMedia();
  };

  const uploadBenefitsMedia = async (smartImport) => {
    setIsLoading(true);
    try {
      const promises = await Promise.allSettled(
        files.map((file) => {
          const { name, data } = file;
          const filetype = encodeURIComponent(data.type.replace(/\//g, '.'));
          const url = `v2/media/${name}/${filetype}?is_private=true&related_type=benefits_package&related_id=${packageId}`;
          const formData = new FormData();
          formData.append('file', data);
          const config = { headers: { 'content-type': 'multipart/form-data' } };
          return CustomAxios.post(url, formData, config);
        })
      );

      const list = promises.filter((item) => {
        const [type = ''] =
          Object.entries(fileTypesMap || {}).find(([_, filetype]) => filetype === item?.value?.data?.ContentType) || [];
        return item?.status === 'fulfilled' && (type === 'xlsx' || type === 'pdf');
      });

      if (smartImport && list?.length) {
        await Promise.allSettled(
          list.map((promise) => {
            const mediaId = promise?.value?.data?.ID;
            return CustomAxios.post(`/v1/benefitspackage/${packageId}/imports/${mediaId}`);
          })
        );
      }
    } catch {
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const handleBenefitsMedia = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const files = acceptedFiles?.reduce((prev, item) => {
        const [type = ''] = Object.entries(fileTypesMap || {}).find(([_, filetype]) => filetype === item?.type) || [];
        return [
          ...prev,
          {
            data: item,
            name: encodeURIComponent(item?.name),
            type: `.${type}`,
          },
        ];
      }, []);

      setFiles(files);
    } else {
      toast.error('Invalid file or too many files');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleBenefitsMedia,
    accept: [fileTypesMap.xlsx, fileTypesMap.xlsm, fileTypesMap.pdf],
    noDragEventsBubbling: true,
  });

  const downloadDocument = async (document) => {
    try {
      const response = await CustomAxios.get(`v2/media/${document?.ID}`, {
        responseType: 'blob',
      });
      toast.success('Downloading...');
      const url = window.URL.createObjectURL(response.data);
      link.current.href = url;
      link.current.download = document?.Name;
      link.current.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      toast.error(`Error downloading file...`);
    }
  };

  const deleteDocument = async (document) => {
    try {
      await CustomAxios.delete(`/v2/media/${document.ID}`);
    } catch (e) {
      console.log(e);
      toast.error('Could not delete document');
    } finally {
      refetchMedia();
    }
  };

  return (
    <Box
      css={`
        display: grid;
        width: 500px;
        padding: 8px;
        gap: 16px;
      `}
      onClick={(e) => e.stopPropagation()}
    >
      {/* eslint-disable-next-line   */}
      <a ref={link} style={{ display: 'none' }} />
      <Text h2>Benefit Package Files</Text>
      <Box
        flex="center"
        css={`
          border-radius: 8px;
          border: 1px dashed ${colors.gray[400]};
          padding: 32px 16px;
          cursor: pointer;
          height: 100px;
          transition: background-color 0.2s ease;
          ${isDragActive ? `background-color: ${colors.gray[100]};` : ''}
        `}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <UploadSimple size={48} color={colors.black} />
        ) : (
          <Text>
            Drop a file here, or{' '}
            <span
              className={css`
                color: ${colors.purple};
              `}
            >
              click to browse
            </span>
          </Text>
        )}
      </Box>
      {media?.map((file) => (
        <Box
          css={`
            border: 1px solid ${colors.gray[300]};
            border-radius: 8px;
            padding: 8px 16px;
            svg {
              margin-right: 16px;
            }
          `}
          flex="left"
        >
          <Text
            css={`
              flex-grow: 1;
              padding: 8px 0;
            `}
            label
          >
            {file?.Name}
          </Text>
          {file?.FileMappingAttempted ? (
            <Tooltip label="Smart Import has been used on this file.">
              <StarFour size={24} color={colors.purple} weight="fill" />
            </Tooltip>
          ) : null}
          <Dropdown
            css={`
              margin: 0;
              padding: 0;
            `}
            listCss={`padding: 8px;`}
            button={() => (
              <Button
                styles="icon sm"
                css={`
                  min-width: 24px;
                `}
              >
                <DotsThree />
              </Button>
            )}
          >
            <Box>
              <Box option onClick={() => downloadDocument(file)}>
                Download
              </Box>
              <Box option onClick={() => deleteDocument(file)}>
                Delete
              </Box>
            </Box>
          </Dropdown>
        </Box>
      ))}

      {files?.length ? (
        <Modal display={true} onClose={closeModal} stopPropagation>
          <Modal.Paper>
            {isLoading ? (
              <>
                <Modal.Header title="Uploading documents..." onClose={closeModal} />
                <Modal.Body>
                  <Box
                    flex="center"
                    css={`
                      margin: 32px 0;
                    `}
                  >
                    <BriteLoader overlay={false} size={128} weight={8} />
                  </Box>
                </Modal.Body>
              </>
            ) : (
              <>
                <Modal.Header
                  title={
                    <Box
                      flex="left"
                      css={`
                        svg {
                          margin-right: 8px;
                        }
                      `}
                    >
                      <AiFileGradient /> <Text h2>Smart import</Text>
                    </Box>
                  }
                  onClose={closeModal}
                />
                <Modal.Body
                  css={`
                    display: grid;
                    gap: 16px;
                  `}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Text label>
                    Hey it looks like we might be able to automatically grab benefit details with Smart Import
                  </Text>
                  {files?.map((file) => (
                    <Box
                      flex="left"
                      css={`
                        border-radius: 8px;
                        padding: 16px;
                        background-color: ${colors.gray[100]};
                        gap: 16px;
                        max-width: 430px;
                      `}
                    >
                      <ExtensionIconMapper fileType={file?.type} size={24} />
                      <Text
                        css={`
                          flex-grow: 1;
                        `}
                        ellipsis
                        label
                      >
                        {file?.name}
                      </Text>
                    </Box>
                  ))}
                </Modal.Body>
                <Modal.Actions>
                  <Button secondary onClick={() => uploadBenefitsMedia(false)}>
                    Upload Only
                  </Button>
                  <Button purpleGradient onClick={() => uploadBenefitsMedia(true)}>
                    <StarFour color="white" weight="fill" size={16} />
                    Smart Import
                  </Button>
                </Modal.Actions>
              </>
            )}
          </Modal.Paper>
        </Modal>
      ) : null}
    </Box>
  );
};
