import { MenuItem } from '@material-ui/core';
import { css } from '@emotion/css';
import { Minus } from 'phosphor-react';
import { Div, Select } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { FieldInput } from './field-input';
import { getFieldProps } from './field-utils';
import { LayoutContainer } from './layout-container';

export const SurestInputField = ({ state, field, config, isSectionSyncing }) => {
  const { product } = state;
  const { PropertyChain } = field;

  const { value, updateProperty } = getFieldProps(state, field, config);
  const { lower, upper } = value;

  const copayType = upper > 0 ? 'Copay Range' : 'Copay';

  return (
    <Div
      css={css`
        width: 100%;
      `}
    >
      <Select
        className={css`
          width: 100%;
        `}
        value={copayType}
        onChange={(event) => updateProperty(`${PropertyChain}Upper`, event.target.value === 'Copay' ? 0 : 1)}
      >
        <MenuItem value="Copay">Copay</MenuItem>
        <MenuItem value="Copay Range">Copay Range</MenuItem>
      </Select>
      <LayoutContainer {...{ state, field, config, isSectionSyncing }}>
        <Div
          css={css`
            width: 100%;
            ${flex('space-between')}
          `}
        >
          <FieldInput
            field={{
              Type: 'dollar',
              PropertyChain: `${PropertyChain}Lower`,
              productId: product?.ID,
            }}
            config={{
              storeValueAs: 'number',
            }}
            value={lower}
            updateProperty={updateProperty}
          />
          {copayType === 'Copay Range' && (
            <>
              <Div
                css={css`
                  margin: 8px;
                  padding: 0 8px;
                  border-left: 1px solid ${colors.gray[300]};
                  border-right: 1px solid ${colors.gray[300]};
                `}
              >
                <Minus />
              </Div>
              <FieldInput
                className={css`
                  margin-right: 8px;
                `}
                field={{
                  Type: 'dollar',
                  PropertyChain: `${PropertyChain}Upper`,
                  productId: product?.ID,
                }}
                config={{
                  storeValueAs: 'number',
                }}
                value={upper}
                updateProperty={updateProperty}
              />
            </>
          )}
        </Div>
      </LayoutContainer>
    </Div>
  );
};
