import { getGenericQueryProps, getUrl } from '.';

const url = '/v1/plansight/employers/:employerId/plantypes/:planType/plans';

export const plansightPlansResource = {
  url,
  key: 'plansight-plans',
  getResourceOptions: (resourceProps) => {
    const resourceUrl = getUrl(url, resourceProps);
    return {
      url: resourceUrl,
      queryProps: {
        initialData: { Data: [] },
        ...getGenericQueryProps(resourceUrl, resourceProps),
      },
    };
  },
  utils: {},
};
