const defaultFieldConfig = {
  // Form view configs
  hideEditLayout: false,
  disabled: false,
  needsAttention: false,
  layoutTrigger: null,
  formatOptions: {},

  // Configs for all views
  hideField: false,
  components: [],
  aboveTheFold: false,
  storeValueAs: 'string',
  roundUp: true,
};

// - components:
//      An array of components to render instead of using the
//      default field type components.

//  - hideEditLayout:
//      Hide the ability to edit show/hide
//      !!!!! >>>>> THIS FIELD WILL BE SET TO "FALSE" IF WE'RE UNABLE TO ACCESS THE LAYOUT section index or field index <<<<< !!!!!          ***************************************************************

//  - hideField:
//      Hide entire field

//  - disabled:
//      Disable the field but keep it shown

//  - needsAttention:
//      Highlight fields that are "required"

//  - storeValueAs
//      This property tells how we should format
//      a value before sending it to the backend
//      OPTIONS: 'string' | 'number'

//  - layoutTrigger:
//      A function that detects changes to the the PRODUCT's
//      PropertyChain value and can update other fields LAYOUT
//      properties

// - aboveTheFold:
//      Whether the field should be above the more details

export const getFieldConfigs = (config, { product, fieldsObject }) => {
  const defaultConfigFn = 'defaultConfig' in config ? config.defaultConfig : null;
  const defaultConfig = defaultConfigFn ? defaultConfigFn({ product, fieldsObject }) : {};
  const configFn = product?.Type in config ? config[product?.Type] : null;
  const productTypeConfig = configFn ? configFn({ product, fieldsObject }) : {};

  return Object.entries({
    ...defaultConfig,
    ...productTypeConfig,
  }).reduce((prev, [key, value]) => {
    return {
      ...prev,
      [key]: {
        ...defaultFieldConfig,
        ...value,
      },
    };
  }, {});
};

export const getSectionConfig = (configuration, section, product) => {
  const configByType = product?.Type in configuration ? configuration[product?.Type]({ product }) : {};
  const config = section?.DisplayValue in configByType ? configByType?.[section?.DisplayValue] : {};
  const defaultConfig = configuration?.defaultConfig?.(section)?.[section?.DisplayValue] || {};
  return {
    ...defaultConfig,
    ...config,
  };
};

export const getFieldsObject = (sections) => {
  if (!sections?.length) {
    return {};
  }
  return sections.reduce((prev, section, sectionIdx) => {
    return section.Fields?.reduce(
      (p, field, fieldIdx) => ({
        ...p,
        [field?.PropertyChain]: {
          ...field,
          fieldIdx,
          sectionIdx,
        },
      }),
      prev
    );
  }, {});
};
