import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { Page } from '../shared/Page/Page';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';

import ButtonUI from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import { CustomAxios } from '../redux/axios/axios';
import TextField from '@material-ui/core/TextField';
import { sortBy } from 'lodash';
import { toast } from 'react-toastify';
import { SurveyResults } from './SurveyResults';
import { Copy, Box, Text, Modal } from '../shared/components';
import { Tooltip } from '../common/components/Tooltip';
import { ChartBar, PaperPlane, Plus, Trash } from 'phosphor-react';

const defaultNewSurvey = {
  Type: 'survey',
  Name: '',
  Views: 0,
  IsPublished: false,
  Pages: [
    {
      Type: 'decision',
      Content: null,
      Name: 'Benefits Explorer Survey',
      Order: 1,
      FrontendID: '_' + Math.random().toString(36).substr(2, 9),
      InsurancePlans: [],
      Question: null,
      IsLocked: false,
      TemplateID: '00000000-0000-0000-0000-000000000000',
    },
  ],
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
}));

export const Surveys = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [addSurveyOpen, setAddSurveyOpen] = useState(false);
  const [deleteSurveyID, setDeleteSurveyID] = useState('');
  const [surveys, setSurveys] = useState([]);
  const [newSurvey, setAddNewSurvey] = useState(defaultNewSurvey);
  const [sharableLinkModal, setSharableLinkModal] = useState('');

  const [vanityURL, setVanityURL] = useState('');
  const [showResultsFromSurvey, setShowResultsFromSurvey] = useState('');

  const getSurveys = async () => {
    try {
      setLoading(true);
      const response = await CustomAxios.get(`/v1/course`);

      let courses = response.data;

      courses = sortBy(courses, (o) => o.Name.toLowerCase());

      courses = courses.filter((course) => course.Type === 'survey');
      setSurveys(courses);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error('Could not retrieve surveys');
    }
  };

  useEffect(() => {
    getSurveys();
  }, []);

  const createVanityUrl = async (survey, url) => {
    const response = await CustomAxios.put(`/v1/course/${survey.ID}/vanityurl`, { URL: url });

    return response;
  };

  const getSharableLink = async (survey) => {
    setSharableLinkModal(survey.ID);
    if (survey.VanityURL) {
      setVanityURL(survey.VanityURL);
    } else {
      let random = Math.floor(Math.random() * (999 - 100 + 1) + 100);
      let url = survey.Name;
      url = url.toLowerCase();
      url = url.replace(/\s/g, '-');
      url = `${url}-${random}`;

      try {
        createVanityUrl(survey, url);
        setVanityURL(url);
      } catch (e) {
        try {
          let url = survey.Name;
          url = url.toLowerCase();
          url = url.replace(/\s/g, '-');
          url = `${url}-${random + 1}`;

          createVanityUrl(survey, url);
          setVanityURL(url);
        } catch (error) {
          toast.error('Could not get url to send survey. Try again.');
        }
      }
    }
  };

  const createSurvey = async () => {
    setAddSurveyOpen(false);
    try {
      setLoading(true);
      await CustomAxios.post(`/v1/course`, newSurvey);

      setAddNewSurvey(defaultNewSurvey);

      getSurveys();
    } catch (e) {
      setLoading(false);
      toast.error('Could not create survey');
    }
  };

  const deleteSurvey = async (id) => {
    try {
      setLoading(true);
      await CustomAxios.delete(`/v1/course/${id}`);
      getSurveys();
    } catch (e) {
      setLoading(false);
      toast.error('Could not delete survey');
    }
  };

  if (loading) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin: 48px;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Page>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
        `}
      >
        <div
          className={css`
            font-weight: bold;
            font-size: 34px;
            line-height: 42px;
            color: #25282d;
            margin-right: 12px;
            margin-left: 8px;
          `}
        >
          Surveys
        </div>

        <Button
          color="primary"
          variant="contained"
          startIcon={<Plus />}
          onClick={() => {
            setAddSurveyOpen(true);
          }}
        >
          Add Survey
        </Button>
      </div>

      {surveys.length === 0 && (
        <div
          className={css`
            margin: 14px;
          `}
        >
          No surveys created yet
        </div>
      )}

      <div
        className={css`
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
        `}
      >
        {surveys.map((survey) => {
          return (
            <Box
              css={`
                margin: 16px;
                width: 230px;
              `}
              key={survey.ID}
            >
              <div
                className={css`
                  margin: 8px;
                  margin-top: 14px;
                `}
              >
                <Chip size="small" label="Benefits Explorer Survey" />
              </div>
              <div
                className={css`
                  padding: 10px;
                  overflow: auto;
                  height: 64px;
                `}
                title={survey.Name}
              >
                <Text
                  css={`
                    font-weight: 500;
                  `}
                >
                  {survey.Name}
                </Text>
              </div>

              <div
                className={css`
                  display: flex;
                  border-top: 1px solid #eee;
                  justify-content: space-between;
                  padding-left: 8px;
                  padding-top: 2px;
                  padding-bottom: 2px;
                `}
              >
                <Tooltip label="View shareable survey link">
                  <ButtonUI
                    startIcon={<PaperPlane />}
                    aria-label="View shareable survey link"
                    color="primary"
                    onClick={() => {
                      getSharableLink(survey);
                    }}
                  >
                    Send
                  </ButtonUI>
                </Tooltip>

                <div>
                  <Tooltip label="Results">
                    <IconButton
                      aria-label="Results"
                      color="default"
                      onClick={() => {
                        setShowResultsFromSurvey(survey.ID);
                      }}
                    >
                      <ChartBar />
                    </IconButton>
                  </Tooltip>
                  <Tooltip label="Delete">
                    <IconButton
                      aria-label="Delete"
                      color="default"
                      onClick={() => {
                        setDeleteSurveyID(survey.ID);
                      }}
                    >
                      <Trash />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Box>
          );
        })}
      </div>

      {!!deleteSurveyID && (
        <Modal display onClose={() => setDeleteSurveyID('')}>
          <div
            className={css`
              font-weight: 400;
              font-size: 22px;
              line-height: 28px;
              color: #797979;
              margin: 20px;
              margin-bottom: 5px;
            `}
          >
            Are you sure you want to delete this survey?
          </div>
          <Box
            css={`
              margin: 24px;
            `}
          >
            <div
              className={css`
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <div
                className={css`
                  margin-right: 24px;
                `}
              >
                <Button
                  onClick={() => {
                    setDeleteSurveyID('');
                  }}
                >
                  Cancel
                </Button>
              </div>
              <Button
                variant="contained"
                onClick={() => {
                  deleteSurvey(deleteSurveyID);
                  setDeleteSurveyID('');
                }}
              >
                Delete
              </Button>
            </div>
          </Box>
        </Modal>
      )}

      {!!showResultsFromSurvey && (
        <Modal
          css={`
            margin: 8px;
            overflow: auto;
          `}
          onClose={() => setShowResultsFromSurvey('')}
        >
          <div
            className={css`
              min-width: 400px;
            `}
          >
            <div
              className={css`
                font-weight: 400;
                font-size: 22px;
                line-height: 28px;
                color: #797979;
                margin: 20px;
                margin-bottom: 5px;
              `}
            >
              Results
            </div>
            <SurveyResults surveyId={showResultsFromSurvey} />
            <Box
              css={`
                margin: 24px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    margin-right: 24px;
                  `}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setShowResultsFromSurvey('');
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Box>
          </div>
        </Modal>
      )}

      {!!sharableLinkModal && (
        <Modal
          display
          onClose={() => {
            setSharableLinkModal('');
            setVanityURL('');
            getSurveys();
          }}
        >
          <div>
            <div
              className={css`
                font-weight: 400;
                font-size: 22px;
                line-height: 28px;
                color: #797979;
                margin: 20px;
                margin-bottom: 5px;
              `}
            >
              Send Survey
            </div>
            <Box
              css={`
                margin: 24px;
              `}
            >
              <Box
                css={`
                  padding: 32px;
                  border-radius: 8px;
                `}
                flex="center"
              >
                {vanityURL && (
                  <React.Fragment>
                    <Copy value={`https://britehr.app/survey/${vanityURL}`}>
                      <div>
                        <Text
                          css={`
                            font-weight: 500;
                          `}
                        >
                          {' '}
                          https://britehr.app/survey/{vanityURL}
                        </Text>
                      </div>
                    </Copy>

                    <div
                      className={css`
                        margin: 14px;
                        font-size: 12px;
                      `}
                    >
                      Share the link above with anyone to take the survey
                    </div>
                  </React.Fragment>
                )}

                {!vanityURL && <CircularProgress />}
              </Box>

              <Button
                variant="contained"
                onClick={() => {
                  setSharableLinkModal('');
                  setVanityURL('');
                  getSurveys();
                }}
              >
                Close
              </Button>
            </Box>
          </div>
        </Modal>
      )}

      {addSurveyOpen && (
        <Modal display onClose={() => setAddSurveyOpen(false)}>
          <div
            className={css`
              font-weight: 400;
              font-size: 22px;
              line-height: 28px;
              color: #797979;
              margin: 20px;
              margin-bottom: 5px;
            `}
          >
            Add Survey
          </div>
          <Box
            css={`
              margin: 24px;
            `}
          >
            <TextField
              id="survey-name"
              label="Survey Name"
              variant="outlined"
              value={newSurvey.Name}
              onChange={(e) => {
                setAddNewSurvey({ ...newSurvey, Name: e.target.value });
              }}
            />

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="type-label">Survey Type</InputLabel>
              <Select
                labelId="type-label"
                id="type-label-select"
                value={newSurvey.Type}
                onChange={(e) => {
                  setAddNewSurvey({ ...newSurvey, Type: e.target.value });
                }}
                label="Survey Type"
              >
                <MenuItem value={'survey'}>Benefits Explorer Survey</MenuItem>
              </Select>
            </FormControl>

            <Button
              color="primary"
              disabled={!newSurvey.Name}
              variant="contained"
              onClick={() => {
                createSurvey();
              }}
            >
              Create Survey
            </Button>
          </Box>
        </Modal>
      )}
    </Page>
  );
};
