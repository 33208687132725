import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { CustomAxios } from '../redux/axios/axios';
import { CircularProgress } from '@material-ui/core';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { Tooltip } from '../common/components/Tooltip';
import { Info } from 'phosphor-react';

export const SurveyResults = (props) => {
  const [startDate] = useState(startOfDay(subDays(new Date(), 30)));
  const [endDate] = useState(endOfDay(new Date()));
  const [analytics, setAnalytics] = useState({
    Recommendations: [],
    Buyups: [],
    Questions: [],
    Activity: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await CustomAxios.get(
          `/v1/analytics/${props.surveyId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        );
        let data = response.data;

        if (!data.Activity) {
          data.Activity = [];
        }
        if (!data.Questions) {
          data.Questions = [];
        }

        if (!data.Recommendations) {
          data.Recommendations = [];
        }
        if (!data.Buyups) {
          data.Buyups = [];
        }

        setAnalytics(data);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    if (props.surveyId) {
      fetchData();
    }
  }, [startDate, endDate, props.surveyId]);

  const getTotalRecommendationsCount = () => {
    let count = 0;
    for (let recommendation of analytics.Recommendations) {
      count += recommendation.SuggestedCount;
    }
    return count;
  };

  if (loading) {
    return (
      <div
        className={css`
          margin: 12px auto;
          display: flex;
          justify-content: center;
        `}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className={css`
        padding: 24px;
        display: flex;
        justify-content: space-between;
      `}
    >
      <div
        className={css`
          border: 1px solid #ededed;
          border-radius: 10px;
          padding: 14px;
          margin: 14px;
          box-shadow: 3px 3px 5px 1px #f1f0f0;
          max-height: 150px;
        `}
      >
        <div
          className={css`
            margin: 14px;
            padding-bottom: 14px;
            border-bottom: 1px solid #ededed;
            font-size: 18px;
            color: #797979;
            line-height: 24px;
          `}
        >
          Total times survey was completed
        </div>
        <div
          className={css`
            font-size: 16px;
            text-align: center;
          `}
        >
          {getTotalRecommendationsCount()}
        </div>
      </div>
      <div>
        <div
          className={css`
            border: 1px solid #ededed;
            border-radius: 10px;
            padding: 14px;
            margin: 14px;
            box-shadow: 3px 3px 5px 1px #f1f0f0;
          `}
        >
          <div
            className={css`
              margin: 14px;
              padding-bottom: 14px;
              border-bottom: 1px solid #ededed;
              font-size: 18px;
              color: #797979;
            `}
          >
            Medical Plans
          </div>

          {analytics.Recommendations.map((recommendation, index) => {
            return (
              <div
                key={index}
                className={css`
                  margin: 14px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 14px;
                  margin-bottom: 14px;
                  padding-bottom: 14px;
                  border-bottom: 1px solid #ededed;
                `}
              >
                <div
                  className={css`
                    width: 150px;
                    margin-right: 14px;
                    font-weight: bold;
                  `}
                >
                  {recommendation.PlanName}
                </div>
                <div
                  className={css`
                    width: 100px;
                    margin-right: 14px;
                  `}
                >
                  <span
                    className={css`
                      font-size: 25px;
                    `}
                  >
                    {recommendation.SuggestedCount}
                  </span>
                  /{getTotalRecommendationsCount()}{' '}
                  <Tooltip
                    label={`The survey was completed ${getTotalRecommendationsCount()} times and this medical plan was the best fit ${
                      recommendation.SuggestedCount
                    } times.`}
                  >
                    <Info />
                  </Tooltip>
                </div>
              </div>
            );
          })}

          {analytics.Recommendations.length === 0 && (
            <div
              className={css`
                margin: 25px;
                text-align: center;
                font-weight: bold;
              `}
            >
              {' '}
              No recommendations have been made yet.
            </div>
          )}
        </div>
        <div
          className={css`
            border: 1px solid #ededed;
            border-radius: 10px;
            padding: 14px;
            margin: 14px;
            box-shadow: 3px 3px 5px 1px #f1f0f0;
          `}
        >
          <div
            className={css`
              margin: 14px;
              padding-bottom: 14px;
              border-bottom: 1px solid #ededed;
              font-size: 18px;
              color: #797979;
            `}
          >
            Additional Benefits
          </div>

          {analytics.Buyups.map((buyup, index) => {
            return (
              <div
                key={index}
                className={css`
                  margin: 14px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 14px;
                  margin-bottom: 14px;
                  padding-bottom: 14px;
                  border-bottom: 1px solid #ededed;
                `}
              >
                <div
                  className={css`
                    width: 150px;
                    margin-right: 14px;
                    font-weight: bold;
                  `}
                >
                  {buyup.DisplayValue}
                </div>
                <div
                  className={css`
                    width: 100px;
                    margin-right: 14px;
                  `}
                >
                  <span
                    className={css`
                      font-size: 25px;
                    `}
                  >
                    {buyup.Count}
                  </span>
                  /{getTotalRecommendationsCount()}{' '}
                  <Tooltip
                    label={`This survey was completed ${getTotalRecommendationsCount()} times and this benefit was a good fit ${
                      buyup.Count
                    } times.`}
                  >
                    <Info />
                  </Tooltip>
                </div>
              </div>
            );
          })}

          {analytics.Buyups.length === 0 && (
            <div
              className={css`
                margin: 25px;
                text-align: center;
                font-weight: bold;
              `}
            >
              {' '}
              No recommendations have been made yet.
            </div>
          )}
        </div>
      </div>{' '}
    </div>
  );
};
