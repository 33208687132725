import { css } from '@emotion/css';
import { ArrowLeft, CalendarBlank, CurrencyDollarSimple, File } from 'phosphor-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { history } from '../history';
import { benefitsPackage, benefitsPackageProductsList, useQueryAPI, useRequests, useResource } from '../react-query';
import { Button, Div, Modal, Text } from '../shared/components';
import { container, flex, shadows } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { useSearchParams } from '../shared/use-search-params';
import { GenerateGuide } from './generate-guide/generate-guide';
import { ProductList } from './product-list';
import { ACLWrapper } from '../shared/components/acl-wrapper';
import { Box } from '../shared/components/box';
import { Dropdown } from '../shared/components/dropdown';
import { extractNumbers } from './auto-paster-v2/paste-utils';
import Dates from '../shared/components/Dates';
import { addDays, format } from 'date-fns';
import { textTheme } from '../shared/components/text';
import { useStateSync } from '../shared/use-state-sync';
import { Tooltip } from '../common/components/Tooltip';
import { UploadMediaDropdown } from './document-to-bp/UploadMediaDropdown';
import { ImportPlansight } from './ImportPlansight';
import { useStore } from '../store-provider/use-store';
import { useFeatureFlagPayload } from 'posthog-js/react';

export const intervalMap = {
  12: 'Monthly',
  52: 'Weekly',
  24: 'Semi-Monthly',
  26: 'Bi-Weekly',
};

export const PackagePage = () => {
  const textareaRef = useRef();
  const params = useParams();
  const {
    params: { setup = '' },
    updateParams,
  } = useSearchParams();

  const store = useStore();

  const plansightImportFF = useFeatureFlagPayload('plansight-import');

  const [modal, setModal] = useState('');
  const [displayCourseGeneration, setDisplayCourseGeneration] = useState(null);

  const benefitsPackageResource = useResource(benefitsPackage);
  const benefitPackageRequests = useRequests(benefitsPackageResource);
  const { data: pkg } = benefitsPackageResource?.query;

  const [pkgName, setPkgName] = useStateSync(pkg?.Name || '', [pkg?.Name]);

  const PremiumIntervalsPerYear = pkg?.PremiumIntervalsPerYear || 0;
  const resource = useResource(benefitsPackageProductsList);

  const { data: media, refetch: refetchMedia } = useQueryAPI({
    url: `v2/media?related_id=${params.packageId}&include_private=true`,
    enabled: !!params.packageId,
    defaultValue: [],
  });

  useEffect(() => {
    if (!setup) {
      resource?.query?.refetch();
    }
  }, [setup]);

  useEffect(() => {
    if (displayCourseGeneration) {
      updateParams({ packageId: params.packageId });
    }
  }, [displayCourseGeneration]);

  const productListRequests = useRequests(resource);

  const reviewProduct = (product) => {
    const putProduct = benefitsPackageProductsList.utils.getPutProduct(product?.ID, {
      ...product,
      ReviewedAt: new Date().toISOString(),
    });
    productListRequests.put(putProduct);
  };

  const savePkg = (updates) => {
    const postPackage = benefitsPackage.utils.getPut(pkg?.ID, {
      ...pkg,
      ...updates,
    });
    benefitPackageRequests.put(postPackage);
  };

  const saveEnrollmentDates = ({ start, end }) => {
    const startDate = start.toISOString();
    const endDate = end.toISOString();
    savePkg({ EnrollmentStartDate: startDate, EnrollmentEndDate: endDate });
  };

  const savePlanDates = ({ start, end }) => {
    const startDate = start.toISOString();
    const endDate = end.toISOString();
    savePkg({ PlanStartDate: startDate, PlanEndDate: endDate });
  };

  const routeToProduct = (product) => {
    if (!product?.ReviewedAt) {
      reviewProduct(product);
    }
    history.push(`/${params.businessId}/packages/${params.packageId}/${product.Type}/${product.ID}`);
  };

  const enrollmentLabel = useMemo(() => {
    if (pkg?.EnrollmentStartDate && pkg?.EnrollmentEndDate) {
      return `${format(Dates.getDateFromISO(pkg?.EnrollmentStartDate), 'MMM dd, yyyy')} - ${format(
        Dates.getDateFromISO(pkg?.EnrollmentEndDate),
        'MMM dd, yyyy'
      )}`;
    }
    return 'Set Enrollment Dates';
  }, [pkg?.EnrollmentStartDate, pkg?.EnrollmentEndDate]);

  const planLabel = useMemo(() => {
    if (pkg?.PlanStartDate && pkg?.PlanEndDate) {
      return `${format(Dates.getDateFromISO(pkg?.PlanStartDate), 'MMM dd, yyyy')} - ${format(
        Dates.getDateFromISO(pkg?.PlanEndDate),
        'MMM dd, yyyy'
      )}`;
    }
    return 'Set Plan Dates';
  }, [pkg?.PlanStartDate, pkg?.PlanEndDate]);

  useEffect(() => {
    textareaRef.current.style.height = 'auto';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }, [pkgName]);

  const routeAddProducts = (type) => {
    updateParams({ setup: type });
  };

  return (
    <>
      <Box page>
        <Div
          css={css`
            ${flex('space-between start')}
          `}
        >
          <Div
            css={css`
              ${flex('ai-center start')}
              flex-grow: 1;
            `}
          >
            <Button styles="icon" onClick={() => history.replace(`/${params.businessId}/packages`)}>
              <ArrowLeft />
            </Button>
            <textarea
              ref={textareaRef}
              value={pkgName}
              onChange={(e) => setPkgName(e.target.value)}
              onBlur={() => savePkg({ Name: pkgName })}
              rows={1}
              className={css`
                ${pkgName?.length > 50 ? textTheme.h2 : textTheme.h1}
                resize: none;
                overflow: hidden;
                outline: none;
                border: none;
                padding: 8px;
                margin: -8px;
                margin-left: 16px;
                border: 1px solid transparent;
                border-radius: 8px;
                min-width: 200px;
                flex-grow: 1;
                margin-right: 16px;
                align-items: top;
                :focus {
                  border: 1px solid ${colors.gray[100]};
                }
                :not(:focus) {
                  ${container.hover}
                }
              `}
            />
          </Div>
          <ACLWrapper acls={['write:benefits_package']} resourceId={params.packageId}>
            <Div
              css={css`
                ${flex('ai-center')}
              `}
            >
              <Button styles="secondary mr" onClick={() => setDisplayCourseGeneration(true)}>
                Generate Guide
              </Button>
              <Dropdown popper={{ placement: 'bottom' }} button={() => <Button>Add Benefit</Button>}>
                <Box
                  option
                  onClick={() => history.push(`/${params.businessId}/packages/${params.packageId}/benefit-type`)}
                >
                  Manual Entry
                </Box>
                {store?.data?.parentBusiness?.Metadata?.APIKeys?.plansight && plansightImportFF?.value ? (
                  <Box option onClick={() => routeAddProducts('plansight')}>
                    Import Plansight
                  </Box>
                ) : null}
                <Box option onClick={() => history.push(`/${params.businessId}/packages/${params.packageId}/aca`)}>
                  Import ACA
                </Box>
              </Dropdown>
            </Div>
          </ACLWrapper>
        </Div>
        <Box
          flex="left"
          css={`
            margin: 32px 0;
            button {
              padding: 8px 16px;
              margin-right: 16px;
              color: ${colors.gray[500]};
              font-weight: bold;
              :hover {
                background-color: ${colors.gray[200]};
              }
            }
          `}
        >
          <Tooltip label={`${media?.length} Document${media?.length === 1 ? '' : 's'}`}>
            <Dropdown
              listCss={`
              padding: 16px;
              border-radius: 16px;
            `}
              popper={{ placement: 'bottom-start' }}
              button={() => (
                <Button light>
                  <File size={24} color={colors.gray[500]} /> Documents
                </Button>
              )}
            >
              <UploadMediaDropdown
                media={media}
                packageId={params.packageId}
                refetchMedia={() => {
                  resource.query.refetch();
                  refetchMedia();
                }}
              />
            </Dropdown>
          </Tooltip>
          <Dropdown
            css={`
              padding: 0;
              :hover {
                background: none;
              }
            `}
            listWidth="250px"
            button={({ open }) => (
              <Tooltip
                label={
                  (PremiumIntervalsPerYear === 0 ? 'Company Kit' : intervalMap?.[PremiumIntervalsPerYear]) ||
                  'Company Kit'
                }
              >
                <Button light>
                  <CurrencyDollarSimple size={24} color={colors.gray[500]} /> Pay Period
                </Button>
              </Tooltip>
            )}
          >
            <Box
              css={`
                padding: 8px;
                p {
                  padding: 8px;
                  border-radius: 8px;
                  ${container.hover}
                }
              `}
              onClick={(e) => e.stopPropagation()}
            >
              <Text
                label
                css={
                  PremiumIntervalsPerYear === 0 ? `background-color: ${colors.gray[100]}; :hover { ${shadows.sm}}` : ''
                }
                onClick={() => savePkg({ PremiumIntervalsPerYear: 0 })}
              >
                Company Kit
              </Text>
              {Object.entries(intervalMap)?.map(([key, label]) => (
                <Text
                  label
                  onClick={() => savePkg({ PremiumIntervalsPerYear: extractNumbers(key, 12) })}
                  css={
                    PremiumIntervalsPerYear === extractNumbers(key, 12)
                      ? `background-color: ${colors.gray[100]}; :hover { ${shadows.sm}}`
                      : ''
                  }
                >
                  {label}
                </Text>
              ))}
            </Box>
          </Dropdown>
          <Tooltip label={enrollmentLabel}>
            <Button light onClick={() => setModal('enrollment')}>
              <CalendarBlank size={24} color={colors.gray[500]} /> Enrollment Dates
            </Button>
          </Tooltip>
          <Tooltip label={planLabel}>
            <Button light onClick={() => setModal('plan')}>
              <CalendarBlank size={24} color={colors.gray[500]} /> Plan Dates
            </Button>
          </Tooltip>
        </Box>
        <Div
          css={css`
            ${flex('left')} width: 100%;
          `}
        >
          <ProductList
            productListResource={resource}
            packageId={params.packageId}
            businessId={params.businessId}
            selectProduct={routeToProduct}
          />
        </Div>
      </Box>
      <GenerateGuide
        display={displayCourseGeneration}
        onClose={() => setDisplayCourseGeneration(null)}
        startStep="design-style"
      />
      {modal === 'enrollment' ? (
        <Modal display={true} onClose={() => setModal('')}>
          <Modal.Paper width="700px">
            <Modal.Header title="Enrollment Dates" onClose={() => setModal('')} />
            <Modal.Body>
              <Dates.Range
                start={Dates.getDateFromISO(pkg?.EnrollmentStartDate)}
                end={Dates.getDateFromISO(pkg?.EnrollmentEndDate)}
                setRange={saveEnrollmentDates}
                autoSetEnd={(start) => start}
              />
            </Modal.Body>
          </Modal.Paper>
        </Modal>
      ) : null}
      {modal === 'plan' ? (
        <Modal display={true} onClose={() => setModal('')}>
          <Modal.Paper width="700px">
            <Modal.Header title="Plan Dates" onClose={() => setModal('')} />
            <Modal.Body>
              <Dates.Range
                start={Dates.getDateFromISO(pkg?.PlanStartDate)}
                end={Dates.getDateFromISO(pkg?.PlanEndDate, addDays(new Date(), 365))}
                setRange={savePlanDates}
                autoSetEnd={(start) => addDays(start, 365)}
              />
            </Modal.Body>
          </Modal.Paper>
        </Modal>
      ) : null}
      {setup === 'plansight' ? <ImportPlansight /> : null}
    </>
  );
};
