import { css } from '@emotion/css';
import { CaretDown, CaretUp, EyeSlash, Info } from 'phosphor-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Div, Icon, Input, Modal, Text } from '../../shared/components';
import { BriteLoader } from '../../shared/components/brite-loader';
import { animation, container, flex, shadows } from '../../shared/shared-styles';
import { useEvent } from '../../shared/use-event';
import { colors } from '../../shared/styles';
import { useDebounceValue } from '../../shared/use-debounce-value';
import { AutoPasteComplete } from './auto-paste-complete';
import { Spreadsheet } from './media-types/spreadsheet';
import { mediaModifiers } from './media-utils';
import { ProductWizard } from './product-wizard';
import { useMedia } from './useMedia';
import { WelcomeModal } from './welcome-modal';
import { pdfjs as reactpdf, Document, Page } from 'react-pdf'; // Documentation: https://github.com/wojtekmaj/react-pdf/blob/main/packages/react-pdf/README.md
// workerContent must be kept in sync with the pdfjs-dist version in package.json. Run the following commands to update it:
// cd src/
// node -e "const fs = require('fs'); fs.writeFileSync('./benefit-package/auto-paster-v2/pdf.worker.min.json', JSON.stringify(fs.readFileSync('../node_modules/pdfjs-dist/build/pdf.worker.min.js', 'utf-8')))"
import workerContent from './pdf.worker.min.json'; // This is a hack discovered here: https://github.com/mozilla/pdf.js/issues/10813
import 'react-pdf/dist/cjs/Page/TextLayer.css';
// import "react-pdf/dist/cjs/Page/AnnotationLayer.css"; -- this makes links clickable. Which makes them hard to copy, so we're not enabling it for now.
import { Dropdown } from '../../shared/components/dropdown';
import { ReactComponent as SheetFocus } from './sheet-focus.svg';
import { useKeyboard } from '../../Router/use-keyboard';
import { isAfter } from 'date-fns';
import { useMediaFocuser } from './use-media-focuser';
import { Tooltip } from '../../common/components/Tooltip';

// react-pdf needs a worker. More info here: https://github.com/wojtekmaj/react-pdf/blob/main/packages/react-pdf/README.md#configure-pdfjs-worker
var workerBlob = new Blob([workerContent], { type: 'text/javascript' });
var workerBlobURL = URL.createObjectURL(workerBlob);
reactpdf.GlobalWorkerOptions.workerSrc = workerBlobURL;

const shortkeys = {
  FOCUS_SEARCH_SHEETS: 'ctrl+KeyS',
  TURN_ON_FOCUS_COLUMNS: 'ctrl+KeyF',
};

const autoPasteContainer = (media) => css`
  position: relative;
  height: calc(100vh - 100px);
  width: 100vw;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  ${flex('column')}
  .container {
    position: relative;
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }
  .media-container {
    position: relative;
    background-color: white;
    height: 100%;
    width: 100%;
    ${animation('fadeIn', '1s ease')}
    .error {
      width: 100%;
      height: 100%;
      ${flex('center')}
      p {
        color: ${colors.red[100]};
      }
    }
  }
  .product-wizard-spacer {
    width: 100%;
    height: 300px;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1000000;
    ${media?.state?.overlay?.display ? 'pointer-events: auto;' : 'pointer-events: none;'}
    transition: background-color .35s ease;
  }
`;

const tabStyles = css`
  position: relative;
  width: 100%;
  min-height: 80px;
  max-height: 80px;
  padding: 16px;
  ${flex('left')}
  overflow-x: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    height: 4px;
    background: white;
    border-top-right-radius: 16px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.gray[400]};
    border-radius: 8px;
  }
  .tab {
    padding: 8px 16px;
    border-radius: 8px;
    background-color: white;
    min-width: max-content;
    ${container.hover}
  }
  .selected-tab {
    background-color: ${colors.gray[100]};
    :hover {
      background-color: ${colors.gray[100]};
    }
    p {
      color: ${colors.black};
    }
  }
`;

export const AutoPaster = ({ state, mediaId, scale, displayAutoFill }) => {
  const media = useMedia(mediaId, state?.productId);
  const mediaType = media?.state?.fileType;

  const [focusColumns, setFocusColumns] = useState([]);
  const [keyboard, setKeyboard] = useState({});

  const { alert, setAlert, changeToFile, changeToSheet } = useMediaFocuser(media, state);

  const keydown = (captured, event) => {
    if (captured === shortkeys.FOCUS_SEARCH_SHEETS) {
      event?.preventDefault();
      setKeyboard({
        updatedAt: new Date().toISOString(),
        captured,
      });
    } else if (captured === shortkeys.TURN_ON_FOCUS_COLUMNS) {
      event?.preventDefault();
      if (media?.state?.focusState === 'focused') {
        mediaModifiers.setFocusState(media, '');
      } else {
        mediaModifiers.setFocusState(media, 'setup-focus');
      }
    }
  };

  useKeyboard({ keydown });

  useEffect(() => {
    if (media?.state?.focusState === '' || media?.state?.focusState === 'setup-focus') {
      setFocusColumns([]);
    }
  }, [media?.state?.focusState]);

  useEffect(() => {
    if (media?.state?.activeSheet) {
      mediaModifiers.setFocusState(media, '');
    }
  }, [media?.state?.activeSheet]);

  const focus = {
    columns: focusColumns,
    setColumns: setFocusColumns,
  };

  return (
    <>
      <Div css={autoPasteContainer(media)}>
        {mediaType !== 'pdf' && <Tabs media={media} state={state} keyboard={keyboard} />}
        <Div className="container">
          <Div className="media-container">
            {media?.state?.hasError ? (
              <Div className="error">
                <Text h1>There was an error parsing your benefits file.</Text>
              </Div>
            ) : mediaType === 'xlsx' ? (
              <Spreadsheet
                media={media}
                focus={focus}
                state={state}
                isLoading={media.isLoading}
                displayAutoFill={displayAutoFill}
              />
            ) : mediaType === 'pdf' ? (
              <PDFViewer media={media} state={state} pdfBuffer={media?.state?.pdf} scale={scale} />
            ) : null}
          </Div>
          {media?.state?.focusState === 'setup-focus' ? (
            <Div
              css={css`
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                ${flex('center')}
                z-index: 10000;
                pointer-events: none;
              `}
            >
              <Div
                css={css`
                  ${container.box}
                  ${shadows.lg}
                  ${flex('space-between')}
                  pointer-events: all;
                  width: 700px;
                  padding: 24px;
                `}
              >
                <Text h4>Select the columns you want to focus on</Text>
                <Div
                  css={css`
                    ${flex('right')}
                  `}
                >
                  <Button
                    secondary
                    css={`
                      margin-right: 16px;
                    `}
                    onClick={() => mediaModifiers.setFocusState(media, '')}
                  >
                    Skip
                  </Button>
                  <Button
                    onClick={() => mediaModifiers.setFocusState(media, 'focused')}
                    disabled={!focusColumns?.length}
                  >
                    Focus
                  </Button>
                </Div>
              </Div>
            </Div>
          ) : alert === 'change-file' ? (
            <Modal display={true} onClose={() => setAlert('')}>
              <Div
                css={css`
                  ${container.box}
                  ${flex('left')}
                  padding: 24px 32px;
                  width: 700px;
                `}
              >
                <Text
                  h4
                  css={`
                    flex-grow: 1;
                  `}
                >
                  This field was mapped from another file.
                </Text>
                <Button
                  secondary
                  css={`
                    margin-right: 16px;
                  `}
                  onClick={() => setAlert('')}
                >
                  Stay
                </Button>
                <Button purple onClick={() => changeToFile(media?.state?.focus?.location?.fileId)}>
                  Go To File
                </Button>
              </Div>
            </Modal>
          ) : alert === 'change-sheet' ? (
            <Modal display={true} onClose={() => setAlert('')}>
              <Div
                css={css`
                  ${container.box}
                  ${flex('left')}
                  padding: 24px 32px;
                  width: 700px;
                `}
              >
                <Text
                  h4
                  css={`
                    flex-grow: 1;
                  `}
                >
                  This field was mapped from another sheet.
                </Text>
                <Button
                  secondary
                  css={`
                    margin-right: 16px;
                  `}
                  onClick={() => setAlert('')}
                >
                  Stay
                </Button>
                <Button purple onClick={() => changeToSheet(media?.state?.focus?.location?.sheet)}>
                  Go To Sheet
                </Button>
              </Div>
            </Modal>
          ) : null}
          <BriteLoader isLoading={media?.isLoading}>
            <Text
              h1
              css={`
                margin: 32px 0;
              `}
            >
              Loading your Benefits File...
            </Text>
            {media?.state?.showLargeFileDisclaimer ? (
              <Div
                css={css`
                  width: 100%;
                  border-radius: 16px;
                  background-color: ${colors.gray[100]};
                  ${flex('left start')}
                  padding: 16px;
                  margin-top: 8px;
                  p {
                    margin-top: 4px;
                  }
                  svg {
                    margin-right: 16px;
                    min-width: 32px;
                  }
                `}
              >
                <Info weight="fill" color={colors.purple} />
                <Text label>
                  This file is quite large. You might want to consider removing any pages that don’t contain information
                  you need in Brite
                </Text>
              </Div>
            ) : null}
          </BriteLoader>
          {media?.state?.disabled ? (
            <Box
              css={`
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 10000;
                background-color: transparent;
                :active {
                  background-color: rgba(255, 255, 255, 0.4);
                }
              `}
            />
          ) : null}
        </Div>
      </Div>

      {!media.isLoading && media?.state?.focusState !== 'setup-focus' ? (
        <ProductWizard state={state} media={media} displayAutoFill={displayAutoFill} />
      ) : null}
      <WelcomeModal />
      <AutoPasteComplete media={media} state={state} />
    </>
  );
};

export const Tabs = ({ media, state, keyboard }) => {
  const searchRef = useRef();
  const [search, setSearch] = useState('');
  const [index, setIndex] = useState(0);
  const [debounce_search] = useDebounceValue(search, 500, [search]);

  const historyByTab = useMemo(() => {
    return (
      media?.historyQuery?.data?.reduce((prev, item) => {
        if (item?.source_metadata?.ref_sheet) {
          const sheet = item?.source_metadata?.ref_sheet;
          return {
            ...prev,
            [sheet]: {
              hasMappingFromProduct: prev?.[sheet]?.hasMappingFromProduct || item?.product_id === state?.productId,
              hasMapping: true,
            },
          };
        }
        return prev;
      }, {}) || {}
    );
  }, [media?.historyQuery?.dataUpdatedAt, state?.productId]);

  useEffect(() => {
    setIndex(0);
    let searchResults = [];
    if (search) {
      for (let rowIdx = 0; rowIdx < media?.state?.worksheet?.length; rowIdx++) {
        for (let colIdx = 0; colIdx < media?.state?.worksheet?.[rowIdx]?.length; colIdx++) {
          const cell = media?.state?.worksheet?.[rowIdx]?.[colIdx];
          if (cell?.value?.toLowerCase().includes(search?.toLowerCase())) {
            searchResults.push(cell?.ref);
          }
        }
      }
    }
    mediaModifiers.setSearchResults(media, searchResults);
  }, [debounce_search, media?.state?.isParsingWorksheet]);

  useEffect(() => {
    if (media?.state?.searchResults?.length && !!search) {
      const cellRef = search ? media?.state?.searchResults?.[index] : '';
      mediaModifiers.mergeFocus(media, {
        location: { cellRef },
      });
    }
  }, [media?.state?.searchResults?.length, index]);

  useEffect(() => {
    if (keyboard?.captured === shortkeys.FOCUS_SEARCH_SHEETS) {
      searchRef.current.focus();
    }
  }, [keyboard?.updatedAt]);

  const { hiddenSheetCount, visibleSheets } = useMemo(() => {
    const defaultValue = {
      hiddenSheetCount: 0,
      visibleSheets: [],
    };
    if (!media?.state?.workbook?.Workbook?.Sheets?.length) {
      return defaultValue;
    }
    const countHiddenSheets = (prev, item, idx) => {
      if (item?.state === 'hidden') {
        return {
          ...prev,
          hiddenSheetCount: prev?.hiddenSheetCount + 1,
        };
      }
      const name = media?.state?.workbook?.SheetNames?.[idx];
      return {
        ...prev,
        visibleSheets: [...prev?.visibleSheets, name],
      };
    };
    return media?.state?.workbook?.Workbook?.Sheets?.reduce(countHiddenSheets, defaultValue);
  }, [media?.state?.workbook?.Workbook?.Sheets?.length]);

  return (
    <Div
      css={css`
        width: 100%;
        ${flex('space-between')}
        z-index: 10000;
        border-bottom: 1px solid ${colors.gray[300]};
      `}
    >
      <Div css={tabStyles}>
        {visibleSheets?.map((item) => {
          const selectedTab = media?.state?.activeSheet === item ? ' selected-tab' : '';
          return (
            <Div
              className={'tab' + selectedTab}
              css={css`
                ${flex('space-between')}
              `}
              onClick={() => mediaModifiers.setActiveSheet(media, item)}
            >
              <Text bold>{item}</Text>
              {historyByTab?.[item]?.hasMapping ? (
                <Div
                  css={css`
                    margin-left: 8px;
                    border-radius: 50%;
                    height: 10px;
                    width: 10px;
                    background-color: ${historyByTab?.[item]?.hasMappingFromProduct ? colors.purple : colors.gray[400]};
                    transition: background-color 1s ease;
                  `}
                />
              ) : null}
            </Div>
          );
        })}
      </Div>
      <Div
        css={css`
          ${animation('fadeIn', '.2s ease')}
          ${flex('right')}
          margin: 8px 16px;
          min-width: max-content;
          border-left: 1px solid ${colors.gray[300]};
          padding-left: 16px;
          button {
            margin: 0 4px;
          }
        `}
      >
        {hiddenSheetCount > 0 ? (
          <Tooltip label={`${hiddenSheetCount} hidden sheets`}>
            <Div
              css={css`
                ${flex('left')}
                p {
                  margin-left: 8px;
                  margin-right: 16px;
                }
              `}
            >
              <EyeSlash />
              <Text label>{hiddenSheetCount}</Text>
            </Div>
          </Tooltip>
        ) : null}

        <Dropdown
          button={({ open }) => (
            <Div
              css={css`
                ${flex('space-between')}
                border-radius: 8px;
                border: 1px solid ${colors.gray[300]};
                width: 80px;
                padding: 6px;
                margin: -8px;
              `}
            >
              <Icon className="sheet-focus" SVG={SheetFocus} color={colors.purple} weight="bold" size={32} />
              <Div
                css={css`
                  border-left: 1px solid ${colors.gray[300]};
                  height: 100%;
                  padding: 8px;
                  padding-bottom: 0;
                `}
              >
                {open ? <CaretUp size={16} /> : <CaretDown size={16} />}
              </Div>
            </Div>
          )}
          listWidth="200px"
        >
          {!media?.state?.focusState ? (
            <Dropdown.Item onClick={() => mediaModifiers.setFocusState(media, 'setup-focus')}>Focus</Dropdown.Item>
          ) : media?.state?.focusState === 'setup-focus' ? (
            <Dropdown.Item onClick={() => mediaModifiers.setFocusState(media, '')}>Turn Off Focus</Dropdown.Item>
          ) : media?.state?.focusState === 'focused' ? (
            <>
              <Dropdown.Item onClick={() => mediaModifiers.setFocusState(media, '')}>Turn Off Focus</Dropdown.Item>
              <Dropdown.Item onClick={() => mediaModifiers.setFocusState(media, 'setup-focus')}>
                Reset Focus
              </Dropdown.Item>
            </>
          ) : null}
        </Dropdown>

        <Input
          ref={searchRef}
          placeholder="Search Sheet"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          endIcon={
            <Text
              css={`
                margin: 0 8px;
                ${media?.state?.searchResults?.length ? '' : 'opacity: .5;'}
              `}
            >
              {media?.state?.searchResults?.length ? index + 1 + '/' + media?.state?.searchResults?.length : ''}
            </Text>
          }
          css={`
            margin-left: 16px;
            :focus-within {
              ${keyboard?.captured === shortkeys.FOCUS_SEARCH_SHEETS
                ? `
                  border: 1px solid ${colors.purple};
                  outline: 1px solid ${colors.purple};
                `
                : ''}
            }
          `}
        />
        <Button styles="icon sm" onClick={() => setIndex(index - 1)} disabled={index === 0}>
          <CaretUp />
        </Button>
        <Button
          styles="icon sm"
          onClick={() => setIndex(index + 1)}
          disabled={index === media?.state?.searchResults?.length - 1}
        >
          <CaretDown />
        </Button>
      </Div>
    </Div>
  );
};

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

// TODO: figure out how to clear selection when the user manually inserts the value (after selecting a piece of the doc). Could just be a little X by the highlight.
// TODO: low-key highlight all the areas where text has already been copied.
// TODO: select smaller portion of already selected text (currently covered by highlighter, so it won't work)

export const PDFViewer = ({ media, state, pdfBuffer, scale }) => {
  const highlighterRef = useRef();
  const [numPages, setNumPages] = useState(0);

  const fieldHistory = useMemo(() => {
    const recentList = media?.historyQuery?.data?.reduce((prev, item) => {
      const createdAt = prev?.[item?.property_chain]?.created_at;
      const isCurrentMoreRecent = isAfter(new Date(item?.created_at), new Date(createdAt));
      if (item?.product_id === state?.productId && (!createdAt || isCurrentMoreRecent)) {
        return {
          ...prev,
          [item?.property_chain]: item,
        };
      }
      return prev;
    }, {});

    return Object.values(recentList ?? {})?.reduce((prev, value) => {
      if (value?.source_metadata?.ref_file_id === media?.state?.mediaId) {
        return {
          ...prev,
          [value?.source_metadata?.ref_page_number]: [
            ...(prev?.[value?.source_metadata?.ref_page_number] ?? []),
            value,
          ],
        };
      }
      return prev;
    }, {});
  }, [media?.historyQuery?.dataUpdatedAt, state?.productId, media?.state?.mediaId]);

  const highlighterWrapperStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
  };

  const [highlighter, setHighlighter] = useState(null);

  const generateFieldStyle = (field) => ({
    position: 'absolute',
    borderRadius: '8px',
    outline: `4px solid ${colors.lightPurple}`,
    opacity: '70%',
    top: field?.source_metadata?.ref_position_top,
    left: field?.source_metadata?.ref_position_left,
    height: field?.source_metadata?.ref_position_height,
    width: field?.source_metadata?.ref_position_width,
    zIndex: '1',
  });

  const updateHighlighterStyle = (pageNumber, top, left, height, width, fieldHistory, suggestion = false) => {
    if (height === '0%') {
      height = '100%';
    }
    if (width === '0%') {
      width = '100%';
    }

    // Update the array with a new entry at pageNumber - 1
    setHighlighter({
      display: true,
      pageNumber,
      fieldHistory,
      style: {
        position: 'absolute',
        outline: `5px solid ${suggestion ? colors.yellow[100] : colors.purple}`,
        outlineOffset: '0',
        borderRadius: '8px',
        top: top,
        left: left,
        height: height,
        width: width,
        zIndex: '1',
      },
    });
  };

  useEffect(() => {
    if (highlighter?.display) {
      highlighterRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [highlighter]);

  const scrollToLocation = media?.state?.focus?.location;

  // If we have history for this field, use it.
  useEffect(() => {
    if (scrollToLocation) {
      if (!scrollToLocation?.pageNumber || scrollToLocation?.pageNumber === '0') {
        setHighlighter({
          display: false,
        });
        return;
      }
      updateHighlighterStyle(
        scrollToLocation.pageNumber,
        scrollToLocation.locationOnPage.top,
        scrollToLocation.locationOnPage.left,
        scrollToLocation.locationOnPage.height,
        scrollToLocation.locationOnPage.width,
        {},
        true
      );
      return;
    }

    const positionInfo = media?.state?.focus?.source?.source_metadata;
    const height = positionInfo?.ref_page_height || positionInfo?.ref_position_height;

    if (!height) {
      // The history does not seem to have what we expected, skip it.
      setHighlighter({
        display: false,
        fieldHistory: media?.state?.focus?.source,
      });
      return;
    }

    // Then update the highlighter style to highlight the previously selected text.
    updateHighlighterStyle(
      positionInfo?.ref_page_number,
      positionInfo?.ref_position_top,
      positionInfo?.ref_position_left,
      positionInfo?.ref_position_height,
      positionInfo?.ref_position_width,
      media?.state?.focus?.source
    );
  }, [media?.state?.focus?.source, media?.state?.fieldIdx, scale, scrollToLocation]);

  const updateFieldFromSelection = (e) => {
    const selection = window.getSelection();
    const selectionStr = selection?.toString();

    // PDF selection can be funky if you get in the margins, so we assume anything over 1000 characters, or less than 2, was a mistake.
    if (!selectionStr || selectionStr.length > 1000 || selectionStr.length < 2) {
      return;
    }

    const innerBoundingRect = selection?.rangeCount > 0 && selection?.getRangeAt(0)?.getBoundingClientRect();

    let maxSteps = 10;
    let parentPageDiv = selection?.baseNode;

    // Loop over selection.baseNode parent nodes until we reach the parent with the data-page-number attribute.
    while (
      parentPageDiv &&
      (!parentPageDiv.attributes || (!parentPageDiv.attributes['data-page-number'] && maxSteps > 0))
    ) {
      parentPageDiv = parentPageDiv.parentNode;
      maxSteps--;
    }

    const pageNumber = parentPageDiv.attributes['data-page-number']?.value;
    if (!pageNumber) {
      console.log('Could not find expected div. Returning early.');
      return;
    }
    const parentPageBoundingRect = parentPageDiv.getBoundingClientRect();

    // innerBoundingRect.top is the distance from the top of the current viewport to the top of the selection.
    // In order to get the distance from the top of the current page to the top of the selection, we need to subtract the distance from the top of the current viewport to the top of the current page.

    let topRelativeToPDFPage = innerBoundingRect.top - parentPageBoundingRect.top;
    let leftRelativeToPDFPage = innerBoundingRect.left - parentPageBoundingRect.left;

    // Add a little space to the top and left to make sure we don't overlap any text.
    leftRelativeToPDFPage -= 4;
    topRelativeToPDFPage -= 4;

    // Need to store these as percentages of the page dimensions so that no matter the scale when we reference them again, they will still work.
    let topAsPercentOfPDFPage = (topRelativeToPDFPage / parentPageBoundingRect.height) * 100 + '%';

    let leftAsPercentOfPDFPage = (leftRelativeToPDFPage / parentPageBoundingRect.width) * 100 + '%';

    // Add 8px to height so that we have 4 extra on both top and bottom to avoid overlap with text.
    let heightPercentOfPDFPage = ((innerBoundingRect.height + 8) / parentPageBoundingRect.height) * 100 + '%';

    // Add 8px to width so that we have 4 extra on both left and right to avoid overlap with text.
    let widthPercentofPDFPage = ((innerBoundingRect.width + 8) / parentPageBoundingRect.width) * 100 + '%';

    updateHighlighterStyle(
      pageNumber,
      topAsPercentOfPDFPage,
      leftAsPercentOfPDFPage,
      heightPercentOfPDFPage,
      widthPercentofPDFPage,
      {}
    );

    e.stopPropagation();
    // Pass the updated value to the store so it can update the product value and be persisted to the database.
    mediaModifiers.acceptInput(media, {
      value: selectionStr,
      propertyChain: media?.state?.currentPropertyChain,
      fieldType: state?.fieldsObject?.[media?.state?.currentPropertyChain],
    });
    mediaModifiers.selectText(
      media,
      {
        value: selectionStr,
        locationOnPage: {
          top: topAsPercentOfPDFPage,
          left: leftAsPercentOfPDFPage,
          height: heightPercentOfPDFPage,
          width: widthPercentofPDFPage,
        },
        originalPageHeight: parentPageBoundingRect.height / scale, // we persist the full page height (before scaling) so that we don't have to rescan the dom after rendering this component to get the page height, and because it could save us headache if the pdfjs lib ever changes.
        originalPageWidth: parentPageBoundingRect.width / scale, // we persist the full page height (before scaling) so that we don't have to rescan the dom after rendering this component to get the page height, and because it could save us headache if the pdfjs lib ever changes.
        pageNumber: pageNumber,
      },
      false
    );
    window?.getSelection()?.empty();
  };

  useEvent('dblclick', updateFieldFromSelection);

  const handleRenderDelayAfterLoad = () => {
    if (highlighterRef?.current) {
      highlighterRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
    setTimeout(handleRenderDelayAfterLoad, 500);
  }

  return (
    <div
      className={css`
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
        overflow-x: auto;
      `}
    >
      <div
        id="pdf-image-viewer"
        onMouseUp={(e) => updateFieldFromSelection(e)}
        className={css`
          border-radius: 8px;
          padding-top: 16px;
          height: calc(100vh - 100px);
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <div style={{ position: 'relative' }}>
          <Document
            file={pdfBuffer}
            onLoadError={(error) => console.error('document on load error ', error)}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <div>
                <div
                  id={'pdf-page-container-' + (index + 1)}
                  style={{
                    border: '5px solid ' + colors.black,
                    borderRadius: '16px',
                    marginBottom: '16px',
                  }}
                >
                  <div
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                      backgroundColor: colors.black,
                      borderRadius: '8px 8px 0 0',
                    }}
                  >
                    <span
                      style={{
                        color: 'white',
                        paddingBottom: '8px',
                        fontSize: '20px',
                      }}
                    >
                      Page {index + 1}
                    </span>
                  </div>
                  <div
                    style={{
                      position: 'relative',
                    }}
                  >
                    <div id="highlighter-wrapper" style={highlighterWrapperStyle}>
                      {/* A div the exact height of the page is required for the text-highlighter to work properly */}
                      {fieldHistory?.[index + 1]?.map((field) => (
                        <div style={generateFieldStyle(field)} />
                      ))}
                      {Number(highlighter?.pageNumber) === index + 1 && (
                        <div ref={highlighterRef} style={highlighter?.style} />
                      )}
                      {/* {highlighter?.display &&
                      Number(highlighter?.pageNumber) === index + 1 &&
                      (!highlighter?.fieldHistory?.id ||
                        highlighter?.fieldHistory?.source_metadata
                          ?.ref_file_id === media?.state?.mediaId) ? (
                        <div ref={highlighterRef} style={highlighter?.style} />
                      ) : null} */}
                    </div>
                    {/* The pageNumber property is important here because we reference it for tracking field sources. Don't change it lightly!*/}
                    <Page
                      key={`pdf_page_${index + 1}`}
                      pageNumber={index + 1}
                      scale={scale}
                      renderAnnotationLayer={false} // We don't want links to render as clickable because it makes them hard to copy in the auto-paster.
                    />
                  </div>
                  {/* Add an extra separator and space to the final entry */}
                  {index + 1 === numPages && (
                    <div>
                      <div
                        style={{
                          justifyContent: 'center',
                          display: 'flex',
                          backgroundColor: colors.black,
                          borderRadius: '0 0 8px 8px',
                        }}
                      >
                        <span
                          style={{
                            color: 'white',
                            padding: '8px',
                            fontSize: '20px',
                          }}
                        >
                          End
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {index + 1 === numPages && <div style={{ height: '200px' }}></div>}
              </div>
            ))}
          </Document>
        </div>
      </div>
    </div>
  );
};
