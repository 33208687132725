import { format, startOfDay } from 'date-fns';
import { css } from '@emotion/css';
import { groupBy, isEmpty } from 'lodash';
import { LineChart } from 'react-chartkick';
import DonutChart from 'react-donut-chart';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import { colors } from '../../../shared/styles';
import { Tooltip } from '../../../common/components/Tooltip';
import { useModal } from '../../../common/hooks/useModal';
import { Button } from '../../../shared/components';
import { EmptyState, EmptyStateSmall } from '../EmptyState';
import { LearnMoreModal } from '../LearnMoreModal';

const graphStyle = (extraStyling) => {
  let styling =
    `
    border-radius: 8px;
    border: 1px solid ${colors.gray[300]};;
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 24px;
    color: ${colors.black};
    font-feature-settings: "clig" off, "liga" off;
  
    /* H4 */
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.15px;
  ` + extraStyling;

  return css`
    ${styling}
  `;
};

export const TrafficTab = ({
  analytics,
  eligibleEmployee,
  startDate,
  endDate,
  getMinDate,
  getMaxDate,
  chartLegendOptions,
  communicationAnnotations,
}) => {
  const learnMoreModal = useModal();

  const getMaxLocationCount = () =>
    analytics.Locations ? analytics.Locations.sort((a, b) => b.Count - a.Count)[0].Count : 0;

  const getMinLocationCount = () =>
    analytics.Locations ? analytics.Locations.sort((a, b) => a.Count - b.Count)[0].Count : 0;

  const countActivityByDay = () => {
    var groups = groupBy(analytics.Activity, function (date) {
      return format(startOfDay(new Date(date.DateStarted)), 'yyyy-MM-dd');
    });
    let obj = {};

    const keys = Object.keys(groups);
    for (const key of keys) {
      obj[key] = groups[key].length;
    }
    return obj;
  };

  const countUniqueActivityByDay = () => {
    let userTracker = {};
    let uniqueDailyActivity = [];
    analytics.Activity.forEach((activity) => {
      const dateStarted = format(startOfDay(new Date(activity.DateStarted)), 'yyyy-MM-dd');
      let uniqueKey = activity.UserIdentifier + String(dateStarted);
      if (!userTracker[uniqueKey]) {
        userTracker[uniqueKey] = true;
        uniqueDailyActivity.push(activity);
      }
    });

    var groups = groupBy(uniqueDailyActivity, function (activity) {
      return format(startOfDay(new Date(activity.DateStarted)), 'yyyy-MM-dd');
    });
    let obj = {};

    const keys = Object.keys(groups);
    for (const key of keys) {
      obj[key] = groups[key].length;
    }
    return obj;
  };

  const countActivityByDayAndMedium = (medium) => {
    var groups = groupBy(analytics.Activity, function (activity) {
      const dateStarted = format(startOfDay(new Date(activity.DateStarted)), 'yyyy-MM-dd');
      return dateStarted;
    });
    let activityCountsByDay = {};
    let totalActivity = 0;

    const keys = Object.keys(groups);
    for (const key of keys) {
      // loop over the array of activities for that day and only count the ones that match the medium
      let filtered = groups[key].filter((activity) => {
        return activity.CommunicationMedium === medium || activity.LinkTracker === medium;
      });
      totalActivity += filtered?.length;
      activityCountsByDay[key] = filtered?.length;
    }

    return [activityCountsByDay, totalActivity];
  };

  const getDeviceCount = () => {
    return analytics?.TotalMobileVisits + analytics?.TotalDesktopVisits;
  };

  const getDeviceData = () => {
    let mobile = 0;
    let desktop = 0;
    if (analytics.UniqueUsers && analytics.TotalMobileVisits) {
      mobile = Math.round((analytics.TotalMobileVisits / analytics.UniqueUsers) * 100);
    }

    if (analytics.UniqueUsers && analytics.TotalDesktopVisits) {
      desktop = Math.round((analytics.TotalDesktopVisits / analytics.UniqueUsers) * 100);
    }

    return [
      {
        label: 'Mobile',
        value: mobile,
      },
      {
        label: 'Desktop',
        value: desktop,
      },
    ];
  };

  const getDotRadius = (count) => {
    let min = getMinLocationCount();
    let max = getMaxLocationCount();

    if (min === max) {
      max = min + 1;
    }
    return ((count - min) / (max - min)) * (50 - 8) + 8;
  };

  const getDevicePercentage = (device) => {
    if (device === 'desktop') {
      let desktop = 0;
      if (analytics.TotalDesktopVisits) {
        desktop = Math.round(
          (analytics.TotalDesktopVisits / (analytics.TotalDesktopVisits + analytics.TotalMobileVisits)) * 100
        );
      }
      return `${desktop}%`;
    } else {
      let mobile = 0;
      if (analytics.TotalMobileVisits) {
        mobile = Math.round(
          (analytics.TotalMobileVisits / (analytics.TotalDesktopVisits + analytics.TotalMobileVisits)) * 100
        );
      }

      return `${mobile}%`;
    }
  };

  const getGraphData = () => {
    return [
      {
        name: 'Total Visitors',
        data: countActivityByDay(),
        color: colors.black,
      },
      {
        name: 'Unique Visitors',
        data: countUniqueActivityByDay(),
        color: colors.purple,
      },
    ];
  };

  const getSourcesGraphData = () => {
    let [otherData, otherCount] = countActivityByDayAndMedium(''); // undefined medium is "other"
    let [emailData, emailCount] = countActivityByDayAndMedium('email');
    let [slackData, slackCount] = countActivityByDayAndMedium('slack');
    let [textData, textCount] = countActivityByDayAndMedium('text');
    let [teamsData, teamsCount] = countActivityByDayAndMedium('teams');
    let [pdfQRCodeData, pdfQRCodeCount] = countActivityByDayAndMedium('pdf-qr-code');
    let [pdfQRCodeManualData, pdfQRCodeManualCount] = countActivityByDayAndMedium('pdf-qr-code-manual');

    let dataWithSources = [
      {
        name: 'Email',
        data: emailData,
        count: emailCount,
      },
      {
        name: 'Texting',
        data: textData,
        count: textCount,
      },
      {
        name: 'Slack',
        data: slackData,
        count: slackCount,
      },
      {
        name: 'Teams',
        data: teamsData,
        count: teamsCount,
      },
      {
        name: 'Brite Handout',
        data: pdfQRCodeData,
        count: pdfQRCodeCount,
      },
      {
        name: 'QR Code',
        data: pdfQRCodeManualData,
        count: pdfQRCodeManualCount,
      },
    ].filter(({ count }) => count > 0);

    const displayColors = [
      '#6D44FB', // purple
      '#C613DB', // purple/pink
      '#F900D1', // pink
      '#FF2567', // pink/orange
      '#FF5C45', // orange
      '#FF8424', // orange/yellow
      '#FFA600', // yellow
    ];

    let totalCount = 0;

    // Assign colors based on most frequent entries.
    let colorCodedData = dataWithSources.sort((a, b) => b.count - a.count);
    for (let i = 0; i < colorCodedData.length; i++) {
      colorCodedData[i].color = displayColors[i];
      totalCount += colorCodedData[i].count;
    }

    // Add new entry to sortedData for "Other" sources (black, to give comms features more pop)
    colorCodedData.push({
      name: 'Other',
      data: otherData,
      count: otherCount,
      color: colors.black,
    });
    totalCount += otherCount;
    colorCodedData = colorCodedData.sort((a, b) => b.count - a.count);

    return { colorCodedData, totalCount };
  };

  const getUniqueUsersPercentage = (users) => {
    if (eligibleEmployee) {
      return Math.round((users / eligibleEmployee) * 100);
    } else {
      return 100;
    }
  };

  return (
    <>
      <div
        className={css`
          display: flex;
          margin-top: 40px;
          align-items: center;
        `}
      >
        <Tooltip label="The total number of visits in a selected time">
          <div
            className={css`
              flex: 1;
              background: #ffffff;
              border: 1px solid ${colors.gray[300]};
              border-radius: 8px;
              padding: 16px 24px;
              margin-right: 24px;
            `}
          >
            {analytics.Activity && analytics.Activity.length > 0 ? (
              <>
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                    color: ${colors.gray[500]};
                  `}
                >
                  Total Guide Visits
                </div>
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 34px;
                    line-height: 48px;
                    letter-spacing: 0.25px;
                    color: ${colors.black};
                  `}
                >
                  {analytics.Activity ? analytics.Activity.length.toLocaleString() : 0}
                </div>
              </>
            ) : (
              <EmptyStateSmall />
            )}
          </div>
        </Tooltip>
        <Tooltip label="An estimate of the total number of actual visitors who accessed your guide">
          <div
            className={css`
              flex: 1;
              background: #ffffff;
              border: 1px solid ${colors.gray[300]};
              border-radius: 8px;
              padding: 16px 24px;
              margin-right: 24px;
            `}
          >
            {analytics.UniqueUsers > 0 ? (
              <>
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                    color: ${colors.gray[500]};
                  `}
                >
                  Unique Visitors
                </div>
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 34px;
                    line-height: 48px;
                    letter-spacing: 0.25px;
                    color: ${colors.black};
                  `}
                >
                  {analytics.UniqueUsers?.toLocaleString()}
                </div>
              </>
            ) : (
              <EmptyStateSmall />
            )}
          </div>
        </Tooltip>
        <Tooltip label="Percentage of employees that have visited this guide">
          <div
            className={css`
              flex: 1;
              background: #ffffff;
              border: 1px solid ${colors.gray[300]};
              border-radius: 8px;
              padding: 16px 24px;
            `}
          >
            {analytics.UniqueUsers > 0 ? (
              <>
                <div
                  className={css`
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                    color: ${colors.gray[500]};
                  `}
                >
                  Percent of Employees
                </div>
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <div
                    className={css`
                      font-family: 'Roboto';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 34px;
                      line-height: 48px;
                      letter-spacing: 0.25px;
                      color: ${colors.black};
                    `}
                  >
                    {getUniqueUsersPercentage(analytics.UniqueUsers)}%
                  </div>
                  <div
                    className={css`
                      color: ${colors.gray[500]};
                      font-feature-settings: 'clig' off, 'liga' off;
                      font-family: Roboto;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 24px; /* 150% */
                      letter-spacing: 0.15px;
                      padding-left: 8px;
                    `}
                  >
                    ({`${analytics.UniqueUsers.toLocaleString()}/${eligibleEmployee.toLocaleString()}`})
                  </div>
                </div>
              </>
            ) : (
              <EmptyStateSmall />
            )}
          </div>
        </Tooltip>
      </div>
      <div
        className={css`
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: ${colors.black};
          margin-top: 40px;
        `}
      >
        Guide Visitors
      </div>
      <div
        className={css`
          border-radius: 8px;
          border: 1px solid ${colors.gray[300]};
          padding: 24px;
          margin-top: 16px;
        `}
      >
        {!isEmpty(countActivityByDay()) || !isEmpty(countUniqueActivityByDay()) ? (
          <LineChart
            adapter="chartjs"
            messages={{ empty: `No Data to Display` }}
            xmin={startDate ? format(startDate, 'yyyy-MM-dd') : getMinDate('yyyy-MM-dd')}
            xmax={endDate ? format(endDate, 'yyyy-MM-dd') : getMaxDate('yyyy-MM-dd')}
            min={0}
            max={countActivityByDay().length}
            scales
            library={{
              plugins: {
                legend: chartLegendOptions,
              },
            }}
            data={getGraphData()}
          />
        ) : (
          <EmptyState />
        )}
      </div>
      <div>
        <div
          className={css`
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: ${colors.black};
            margin-top: 40px;
          `}
        >
          Visits By Source
        </div>
        <div
          className={css`
            border-radius: 8px;
            background: ${colors.gray[100]};
            display: flex;
            padding: 16px 24px;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;
            margin-right: 12px;
          `}
        >
          <div
            className={css`
              color: ${colors.black};
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.15px;
            `}
          >
            To track visitor sources, send your guide with Brite’s Communication Tools
          </div>
          <div
            className={css`
              display: flex;
              gap: 16px;
              align-items: center;
            `}
          >
            <Button
              css={css`
                text-transform: 'none';
              `}
              onClick={() => learnMoreModal.toggle()}
            >
              Learn More
            </Button>
          </div>
        </div>
        <div>
          <div
            className={css`
              display: flex;
              padding-top: 24px;
              padding-bottom: 24px;
              gap: 24px;
            `}
          >
            {getSourcesGraphData().totalCount > 0 ? (
              <>
                <div className={graphStyle('flex-basis: 33.33%;')}>
                  {getSourcesGraphData().colorCodedData.map((source) => {
                    return (
                      <div
                        className={css`
                          color: ${colors.black};
                          font-feature-settings: 'clig' off, 'liga' off;

                          /* Label */
                          font-family: Roboto;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 700;
                          line-height: 24px; /* 171.429% */
                          letter-spacing: 0.15px;
                        `}
                        key={source.name}
                      >
                        <div
                          className={css`
                            display: flex;
                            justify-content: space-between;
                          `}
                        >
                          <div>{source.name}</div>
                          <div
                            className={css`
                              color: ${colors.gray[500]};
                              font-feature-settings: 'clig' off, 'liga' off;
                              font-family: Roboto;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 24px; /* 171.429% */
                              letter-spacing: 0.15px;
                            `}
                          >
                            {(source.count / getSourcesGraphData().totalCount) * 100 > 1
                              ? Math.floor((source.count / getSourcesGraphData().totalCount) * 100)
                              : ((source.count / getSourcesGraphData().totalCount) * 100).toFixed(2)}
                            %
                          </div>
                        </div>
                        <div
                          className={css`
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            align-self: stretch;
                            border-radius: 8px;
                            background: ${colors.gray[200]};
                            margin-top: 4px;
                            margin-bottom: 8px;
                          `}
                        >
                          <div
                            className={css`
                              width: ${source.count ? (source.count / getSourcesGraphData().totalCount) * 100 : 0}%;
                              height: 8px;
                              border-radius: 8px;
                              background: ${source.color};
                            `}
                          ></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={graphStyle('flex-basis: 63.33%;')}>
                  <LineChart
                    adapter="chartjs"
                    messages={{ empty: 'No data' }}
                    xmin={startDate ? format(startDate, 'yyyy-MM-dd') : getMinDate('yyyy-MM-dd')}
                    xmax={endDate ? format(endDate, 'yyyy-MM-dd') : getMaxDate('yyyy-MM-dd')}
                    min={1}
                    max={countActivityByDay().length}
                    scales
                    library={{
                      plugins: {
                        legend: chartLegendOptions,
                        annotation: {
                          annotations: communicationAnnotations,
                        },
                      },
                    }}
                    data={getSourcesGraphData().colorCodedData}
                  />
                </div>
              </>
            ) : (
              <div
                className={css`
                  border-radius: 8px;
                  border: 1px solid ${colors.gray[300]};
                  margin-top: 12px;
                  margin-bottom: 12px;
                  padding: 24px;
                  width: 100%;
                `}
              >
                <EmptyState />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={css`
          display: flex;
        `}
      >
        <div
          className={css`
            flex: 1;
            margin-right: 40px;
          `}
        >
          {' '}
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              color: ${colors.black};
              margin-top: 40px;
              margin-bottom: 24px;
            `}
          >
            Visitor Locations
          </div>
          <div>
            {analytics.Locations ? (
              <div
                className={css`
                  border: 1px solid ${colors.gray[300]};
                  border-radius: 8px;
                  padding: 32px;
                `}
              >
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  {analytics.Locations.sort((a, b) => b.Count - a.Count).map((l, index) => {
                    if (index > 2) {
                      return null;
                    }
                    return (
                      <div
                        className={css`
                          padding: 16px;
                          padding-left: 24px;
                          padding-right: 24px;
                          margin-right: 24px;
                          margin-bottom: 24px;
                          width: 230px;
                        `}
                        key={l.CityName}
                      >
                        <div
                          className={css`
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.15px;
                            color: ${colors.gray[500]};
                          `}
                        >
                          {l.CityName}
                        </div>
                        <div
                          className={css`
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 34px;
                            line-height: 48px;
                            letter-spacing: 0.25px;
                            color: ${colors.black};
                          `}
                        >
                          {l.Count}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  className={css`
                    font-size: 18px;
                  `}
                >
                  <ComposableMap projection="geoAlbers">
                    <ZoomableGroup zoom={1} maxZoom={1}>
                      <Geographies geography={'https://cdn.jsdelivr.net/npm/world-atlas@2/countries-50m.json'}>
                        {({ geographies }) =>
                          geographies.map((geo) => (
                            <Geography key={geo.rsmKey} geography={geo} fill="#DDD" stroke="#FFF" />
                          ))
                        }
                      </Geographies>
                      {analytics.Locations.map((l, index) => (
                        <Marker key={index} coordinates={[l.Long, l.Lat]}>
                          <circle r={getDotRadius(l.Count)} fill={colors.black} />
                        </Marker>
                      ))}
                    </ZoomableGroup>
                  </ComposableMap>
                </div>
              </div>
            ) : (
              <div
                className={css`
                  border: 1px solid ${colors.gray[300]};
                  border-radius: 8px;
                  padding: 32px;
                `}
              >
                <EmptyState />
              </div>
            )}
          </div>
        </div>
        <div
          className={css`
            width: 320px;
          `}
        >
          <div
            className={css`
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              color: ${colors.black};
              margin-top: 40px;
              margin-bottom: 24px;
            `}
          >
            Visitor Devices
          </div>
          <div
            className={css`
              border: 1px solid ${colors.gray[300]};
              border-radius: 8px;
              padding-top: 32px;
              padding-left: 32px;
              padding-right: 32px;
              padding-bottom: 56px;
            `}
          >
            {getDeviceCount() > 0 ? (
              <>
                <div
                  className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div
                    className={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <div
                      className={css`
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: ${colors.gray[400]};
                        margin-right: 16px;
                      `}
                    ></div>
                    <div>Desktop</div>
                  </div>
                  <div>{getDevicePercentage('desktop')}</div>
                </div>
                <div
                  className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 16px;
                  `}
                >
                  <div
                    className={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <div
                      className={css`
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: ${colors.black};
                        margin-right: 16px;
                      `}
                    ></div>
                    <div>Mobile</div>
                  </div>
                  <div>{getDevicePercentage('mobile')}</div>
                </div>
                <div
                  className={css`
                    padding: 20px;
                    align-items: center;
                  `}
                >
                  <DonutChart
                    interactive={false}
                    clickToggle={false}
                    selectedOffset={0}
                    height="216"
                    strokeColor={'transparent'}
                    legend={false}
                    width="216"
                    colors={[colors.black, '#9AA7B5']} // mobile then desktop
                    data={getDeviceData()}
                  />
                </div>
                <div
                  className={css`
                    position: relative;
                    top: -166px;
                    margin-bottom: -89px;
                  `}
                >
                  <div
                    className={css`
                      font-family: 'Roboto';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 34px;
                      line-height: 48px;
                      text-align: center;
                      letter-spacing: 0.25px;
                      color: ${colors.black};
                    `}
                  >
                    {getDeviceCount() || '0'}
                  </div>
                  <div
                    className={css`
                      font-family: 'Roboto';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      text-align: center;
                      letter-spacing: 0.15px;
                      color: ${colors.gray[500]};
                    `}
                  >
                    Known Devices
                  </div>
                </div>
              </>
            ) : (
              <EmptyState />
            )}
          </div>
        </div>
      </div>
      {learnMoreModal.isOpen && <LearnMoreModal hideLearnMore={() => learnMoreModal.hide()} />}
    </>
  );
};
