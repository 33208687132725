import { toast } from 'react-toastify';
import { CustomAxios } from './axios/axios';
import { cloneDeep, groupBy } from 'lodash';

const planNetworkDefault = {
  show: false,
  Name: '',
  PlanType: '',
  ApplicableStates: [],
  ApplicableZipCodes: [],
  EmployeeContributionType: '%',
  HSACompatible: false,
  HSAContributionType: 'HSA - No Employer Contribution',
  HideEmployerPremiumContributions: false,
  TotalMonthlyPremiums: {
    EmployeeOnly: 0,
    EmployeeSpouse: 0,
    EmployeeChildren: 0,
    Family: 0,
  },
  EmployeeContributions: {
    EmployeeOnly: 0,
    EmployeeSpouse: 0,
    EmployeeChildren: 0,
    Family: 0,
  },
  HSAContributions: {
    EmployeeOnly: 0,
    EmployeeSpouse: 0,
    EmployeeChildren: 0,
    Family: 0,
    EmployeeOnlyEmployerMonthlyMatch: 0,
    EmployeeSpouseEmployerMonthlyMatch: 0,
    EmployeeChildrenEmployerMonthlyMatch: 0,
    FamilyEmployerMonthlyMatch: 0,
    EmployeeOnlyEmployerMonthlyMatchMax: 0,
    EmployeeSpouseEmployerMonthlyMatchMax: 0,
    EmployeeChildrenEmployerMonthlyMatchMax: 0,
    FamilyEmployerMonthlyMatchMax: 0,
  },
};

// FROM REDUX SAGA 'AUTH' - createBusinessRelationship
export const createChildBusiness = async (payload, details) => {
  try {
    const { Name, EmployeeCount, websiteURL, companyState, industry, firstName, lastName, email, inviteUser } = payload;

    const newBusiness = {
      EmployeeCount: Number(EmployeeCount),
      Name: Name,
      IsActive: false,
      Type: 'single',
      LogoURL: '',
      Domain: websiteURL,
      State: companyState,
      Industry: industry,
      PaymentSource: 'multi',
    };

    const response = await CustomAxios.post(`/v2/business`, newBusiness);

    const businessResp = response.data;

    const userNew = {
      FirstName: firstName || '',
      LastName: lastName || '',
      Email: email,
      Type: 'singleAdmin',
      Business: {
        ID: businessResp.ID,
      },
    };

    await CustomAxios.post(`/v2/business/relationship`, {
      ParentID: details.businessId,
      ChildID: businessResp.ID,
    });

    try {
      if (inviteUser) {
        await CustomAxios.post(`/v1/users/${email}`, userNew, {
          headers: {
            BusinessID: businessResp.ID,
          },
        });
      } else {
        await CustomAxios.post(`/v1/users`, userNew, {
          headers: {
            BusinessID: businessResp.ID,
          },
        });
      }
    } catch (e) {
      console.log('did not create user');
      if (e.response && e.response.status === 409) {
        toast.error('Could not add user to new company because they are assigned to a different business');
      }
    }

    const bizResponse = await CustomAxios.get(`v2/business/user/${details.userId}`);
    const multiBiz = bizResponse.data;
    const createBiz = multiBiz.Parent;
    createBiz.companies = multiBiz.Companies;
  } catch (error) {
    toast.error('Could not create company');
  }
};

export const deleteBusinessRelationship = async (payload, details) => {
  try {
    let deleteBusiness = {
      ParentID: details.businessId,
      ChildID: payload,
    };

    await CustomAxios.delete(`/v2/business/${payload}`, {
      data: deleteBusiness,
    });

    await CustomAxios.delete(`/v2/business/relationship`, {
      data: deleteBusiness,
    });

    let bizResponse = await CustomAxios.get(`v2/business/user/${details.userId}`);
    let multiBiz = bizResponse.data;
    let createBiz = multiBiz.Parent;
    createBiz.companies = multiBiz.Companies;
  } catch (error) {
    toast.error('Could not delete company');
  }
};

export const copyCourseFn = async (courseId, updates = {}) => {
  const url = `/v1/course/${courseId}/copies`;
  const copyResp = await CustomAxios.post(url, {
    Name: updates.Name,
    ConvertToBriteEditor: updates.ConvertToBriteEditor,
    CourseFolderID: null,
  });
  const response = await CustomAxios.get(`/v2/course/${copyResp.data.CourseID}`);

  return response.data;
};

export const groupMultiNetworkPlans = (grouped) => {
  let plans = [];
  for (const key of Object.keys(grouped)) {
    let groupedPlans = grouped[key];
    if (key === '') {
      for (let plan of groupedPlans) {
        plan.buyUpNetwork = planNetworkDefault;
        plan.narrowNetwork = planNetworkDefault;
        plan.grouped = true;
        plans.push(plan);
      }
    } else {
      let groupedPlans = grouped[key];
      let newPlan = {};

      for (let plan of groupedPlans) {
        if (plan.MultiNetworkCategory === 'core') {
          newPlan = cloneDeep(plan);
        }
      }
      for (let plan of groupedPlans) {
        if (plan.MultiNetworkCategory === 'buyup') {
          newPlan.buyUpNetwork = {
            show: true,
            ID: plan.ID,
            Name: plan.Name,
            PlanType: plan.PlanType,
            ApplicableStates: plan.ApplicableStates,
            ApplicableZipCodes: plan.ApplicableZipCodes,
            EmployeeContributionType: plan.EmployeeContributionType,
            HSACompatible: plan.HSACompatible,
            HSAContributionType: plan.HSAContributionType,
            HideEmployerPremiumContributions: plan.HideEmployerPremiumContributions,
            TotalMonthlyPremiums: {
              EmployeeOnly: plan.TotalMonthlyPremiums.EmployeeOnly,
              EmployeeSpouse: plan.TotalMonthlyPremiums.EmployeeSpouse,
              EmployeeChildren: plan.TotalMonthlyPremiums.EmployeeChildren,
              Family: plan.TotalMonthlyPremiums.Family,
            },
            EmployeeContributions: {
              EmployeeOnly: plan.EmployeeContributions.EmployeeOnly,
              EmployeeSpouse: plan.EmployeeContributions.EmployeeSpouse,
              EmployeeChildren: plan.EmployeeContributions.EmployeeChildren,
              Family: plan.EmployeeContributions.Family,
            },
            HSAContributions: {
              EmployeeOnly: plan.HSAContributions.EmployeeOnly,
              EmployeeSpouse: plan.HSAContributions.EmployeeSpouse,
              EmployeeChildren: plan.HSAContributions.EmployeeChildren,
              Family: plan.HSAContributions.Family,
              EmployeeOnlyEmployerMonthlyMatch: plan.HSAContributions.EmployeeOnlyEmployerMonthlyMatch,
              EmployeeSpouseEmployerMonthlyMatch: plan.HSAContributions.EmployeeSpouseEmployerMonthlyMatch,
              EmployeeChildrenEmployerMonthlyMatch: plan.HSAContributions.EmployeeChildrenEmployerMonthlyMatch,
              FamilyEmployerMonthlyMatch: plan.HSAContributions.FamilyEmployerMonthlyMatch,
              EmployeeOnlyEmployerMonthlyMatchMax: plan.HSAContributions.EmployeeOnlyEmployerMonthlyMatchMax,
              EmployeeSpouseEmployerMonthlyMatchMax: plan.HSAContributions.EmployeeSpouseEmployerMonthlyMatchMax,
              EmployeeChildrenEmployerMonthlyMatchMax: plan.HSAContributions.EmployeeChildrenEmployerMonthlyMatchMax,
              FamilyEmployerMonthlyMatchMax: plan.HSAContributions.FamilyEmployerMonthlyMatchMax,
            },
          };
        } else if (plan.MultiNetworkCategory === 'narrow') {
          newPlan.narrowNetwork = {
            show: true,
            ID: plan.ID,
            Name: plan.Name,
            PlanType: plan.PlanType,
            ApplicableStates: plan.ApplicableStates,
            ApplicableZipCodes: plan.ApplicableZipCodes,
            EmployeeContributionType: plan.EmployeeContributionType,
            HSACompatible: plan.HSACompatible,
            HSAContributionType: plan.HSAContributionType,
            HideEmployerPremiumContributions: plan.HideEmployerPremiumContributions,
            TotalMonthlyPremiums: {
              EmployeeOnly: plan.TotalMonthlyPremiums.EmployeeOnly,
              EmployeeSpouse: plan.TotalMonthlyPremiums.EmployeeSpouse,
              EmployeeChildren: plan.TotalMonthlyPremiums.EmployeeChildren,
              Family: plan.TotalMonthlyPremiums.Family,
            },
            EmployeeContributions: {
              EmployeeOnly: plan.EmployeeContributions.EmployeeOnly,
              EmployeeSpouse: plan.EmployeeContributions.EmployeeSpouse,
              EmployeeChildren: plan.EmployeeContributions.EmployeeChildren,
              Family: plan.EmployeeContributions.Family,
            },
            HSAContributions: {
              EmployeeOnly: plan.HSAContributions.EmployeeOnly,
              EmployeeSpouse: plan.HSAContributions.EmployeeSpouse,
              EmployeeChildren: plan.HSAContributions.EmployeeChildren,
              Family: plan.HSAContributions.Family,
              EmployeeOnlyEmployerMonthlyMatch: plan.HSAContributions.EmployeeOnlyEmployerMonthlyMatch,
              EmployeeSpouseEmployerMonthlyMatch: plan.HSAContributions.EmployeeSpouseEmployerMonthlyMatch,
              EmployeeChildrenEmployerMonthlyMatch: plan.HSAContributions.EmployeeChildrenEmployerMonthlyMatch,
              FamilyEmployerMonthlyMatch: plan.HSAContributions.FamilyEmployerMonthlyMatch,
              EmployeeOnlyEmployerMonthlyMatchMax: plan.HSAContributions.EmployeeOnlyEmployerMonthlyMatchMax,
              EmployeeSpouseEmployerMonthlyMatchMax: plan.HSAContributions.EmployeeSpouseEmployerMonthlyMatchMax,
              EmployeeChildrenEmployerMonthlyMatchMax: plan.HSAContributions.EmployeeChildrenEmployerMonthlyMatchMax,
              FamilyEmployerMonthlyMatchMax: plan.HSAContributions.FamilyEmployerMonthlyMatchMax,
            },
          };
        }
      }

      if (!newPlan.buyUpNetwork) {
        newPlan.buyUpNetwork = planNetworkDefault;
      }

      if (!newPlan.narrowNetwork) {
        newPlan.narrowNetwork = planNetworkDefault;
      }
      newPlan.grouped = true;
      plans.push(newPlan);
    }
  }

  return plans;
};

export const handleUpdateBusiness = async (payload) => {
  try {
    let updateBusiness = {
      EmployeeCount: Number(payload.EmployeeCount),
      ID: payload.ID,
      Name: payload.Name,
      IsActive: payload.IsActive,
      Type: payload.Type,
      Domain: payload.Domain,
      State: payload.State,
      Industry: payload.Industry,
      LogoURL: payload.LogoURL,
      PaymentSource: payload.PaymentSource,
      ParentID: payload.ParentID,
      BrokerContactName: payload.BrokerContactName,
      BrokerContactEmail: payload.BrokerContactEmail,
      BrokerContactPhone: payload.BrokerContactPhone,
      BrokerContactTitle: payload.BrokerContactTitle,
      HRContactName: payload.HRContactName,
      HRContactEmail: payload.HRContactEmail,
      HRContactPhone: payload.HRContactPhone,
      HRContactTitle: payload.HRContactTitle,
      Metadata: payload.Metadata,
    };

    const response = await CustomAxios.put(`/v2/business/${payload.ID}`, updateBusiness);

    let business = response.data;

    if (payload.Type === 'carrier') {
      await CustomAxios.put(`/v1/carrier/${payload.ID}/details`, {
        CarrierID: payload.ID,
        Description: payload.Description,
      });

      let categoryIds = payload.Categories.map((category) => {
        return category.ID;
      });

      await CustomAxios.put(`/v1/business/${payload.ID}/categories`, {
        CategoryIDs: categoryIds,
      });

      business.carrier = { Description: payload.Description };
      business.categories = payload.Categories;
    }
    toast.success('Company information updated');
  } catch (error) {
    console.log(error);
    toast.error('Could not update company information');
  }
};
