import posthog from 'posthog-js';
import { toast } from 'react-toastify';
import { CustomAxios } from '../redux/axios/axios';

export const handleUser = async (email) => {
  const response = await CustomAxios.get(`/public/v1/users/${email}`);
  const user = response.data;

  posthog.identify(user.ID, {
    assignedBusiness: user.Business.ID,
    email: user.Email,
    userType: user.Type.replace('Admin', ''),
    isAdmin: user.Type.includes('Admin'),
  });

  if (user.MostRecentPreviousLogin) {
    const since = new Date(user.MostRecentPreviousLogin);
    const daysSinceLastLogin = Math.floor((new Date() - since) / 1000 / 60 / 60 / 24);

    // last login must be at least greater than 1 day ago so we don't accidentally spam users
    if (daysSinceLastLogin > 1) {
      try {
        const recentAnalyticsResp = await CustomAxios.get(`/v1/analytics/recent?since=${user.MostRecentPreviousLogin}`);
        const recentAnalytics = recentAnalyticsResp.data;
        // only show recent analytics if there were at least 500 views since last login or we averaged greater than 50 views per day
        if (
          recentAnalytics.CourseViews &&
          (recentAnalytics.CourseViews.Total > 500 || recentAnalytics.CourseViews.Total / daysSinceLastLogin > 50)
        ) {
          const acrossCompaniesMsg =
            recentAnalytics.CourseViews.CompanyCount > 1
              ? `, across ${recentAnalytics.CourseViews.CompanyCount.toLocaleString()} different companies,`
              : '';
          const sinceMsg = ` since you last logged in on ${since.toLocaleDateString()}`;
          posthog.capture('Showed Recent Analytics', {
            recentAnalytics: recentAnalytics,
          });
          toast.info(
            `Welcome back! We wanted to tell you that your guides were clicked ${recentAnalytics.CourseViews.Total.toLocaleString()} times${acrossCompaniesMsg}${sinceMsg}.`,
            { autoClose: 7000 }
          );
        }
      } catch (e) {
        console.log('error getting recent analytics--will continue with login anyways', e);
      }
    }
  }

  return user;
};

const transformAclList = (data, list) => {
  let dedupe = [];

  const companies = list.filter(({ ID }) => {
    if (!dedupe.includes(ID)) {
      dedupe.push(ID);
      return true;
    }
    return false;
  });

  const acls = data.reduce(
    (prev, item) => ({
      ...prev,
      [item.ResourceID]: { ...item },
    }),
    {}
  );

  return { companies, acls };
};

export const getBusinessAcls = async () => {
  try {
    const response = await CustomAxios.get(`v1/users/me/acls`);
    const acls = response.data.Data;

    let list = [];
    let next = '/v2/business?limit=1000&skip=0';
    while (!!next) {
      const businessResp = await CustomAxios.get(next);
      list = list.concat(businessResp.data.Data);
      next = businessResp.data.Next;
    }

    return transformAclList(acls, list);
  } catch (err) {
    console.warn(err);
    return { companies: [], acls: {} };
  }
};
