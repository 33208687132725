import { MenuItem } from '@material-ui/core';
import { css } from '@emotion/css';
import { set } from 'lodash';
import { useState } from 'react';
import { Div, Select } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { FieldInput, getStoredValue } from './field-input';
import { getFieldProps } from './field-utils';
import { LayoutContainer, LayoutSelect } from './layout-container';
import { colors } from '../../shared/styles';

export const DrugDeductible = ({ state, field, config, isSectionSyncing }) => {
  const { product } = state;
  const { value, updateProperty } = getFieldProps(state, field, config);

  const { PropertyChain } = field;
  const [stateSyncedAt, setStateSyncedAt] = useState('');

  const updateField = (key, updates) => {
    let nextData = { ...value };
    set(nextData, key, updates);
    updateProperty(PropertyChain, nextData);
  };

  const updateMedicalDeductible = (type) => {
    if (type === 'Medical Deductible Applies') {
      updateField('Value', -1);
    } else if (type === 'None') {
      updateField('Value', -2);
    } else if (type === 'Flat Rate') {
      updateField('Value', 0);
    }
  };

  const currentType = value.Value === -1 ? 'Medical Deductible Applies' : value.Value === -2 ? 'None' : 'Flat Rate';

  const Type = value.PercentOrDollar === '$' ? 'dollar' : value.PercentOrDollar === '%' ? 'percent' : 'dollar';

  const handlePasteValue = (fieldLayout, updates) => {
    const dollarIndex = updates?.indexOf('$');
    const percentIndex = updates?.indexOf('%');

    let next = value;

    if (percentIndex > -1 || dollarIndex > -1) {
      if (percentIndex > dollarIndex) {
        next.PercentOrDollar = '%';
      } else {
        next.PercentOrDollar = '$';
      }
    }

    next.Value = getStoredValue(fieldLayout, updates);
    updateProperty(PropertyChain, next);
    setStateSyncedAt(new Date().toISOString());
  };

  return (
    <Div
      css={css`
        width: calc(100% - 40px);
      `}
    >
      <Select
        className={css`
          width: 100%;
          ${config?.disabled
            ? `
            background-color: ${colors.gray[100]};
          `
            : ''}
        `}
        disabled={config?.disabled}
        value={currentType}
        onChange={(e) => updateMedicalDeductible(e.target.value)}
      >
        <MenuItem value="Medical Deductible Applies">Medical Deductible Applies</MenuItem>
        <MenuItem value="Flat Rate">Flat Rate</MenuItem>
        <MenuItem value="None">None</MenuItem>
      </Select>
      {value?.Value >= 0 && (
        <LayoutContainer {...{ state, field, config, isSectionSyncing }}>
          <Div
            css={css`
              ${flex('right')}
              width: 100%;
            `}
          >
            <FieldInput
              field={{
                Type,
                PropertyChain: `${PropertyChain}.Value`,
                productId: product?.ID,
              }}
              config={{
                storeValueAs: 'number',
              }}
              handlePasteValue={handlePasteValue}
              stateSyncedAt={stateSyncedAt}
              value={Math.max(0, parseFloat(value?.Value) || 0)}
              updateProperty={updateProperty}
            />
            <LayoutSelect
              value={value?.PercentOrDollar}
              onChange={(e) => updateProperty(`${PropertyChain}.PercentOrDollar`, e.target.value)}
            >
              <MenuItem value="$">Dollar</MenuItem>
              <MenuItem value="%">Percent</MenuItem>
            </LayoutSelect>
          </Div>
        </LayoutContainer>
      )}
    </Div>
  );
};
