import { useRef } from 'react';
import { useEvent } from './use-event';

export const useOutsideClick = (fn, options = {}) => {
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    try {
      if (
        ref?.current?.contains(event.target) ||
        options?.disableClose ||
        (options?.disableRightClick && event.button === 2)
      ) {
        return;
      }
      fn(event);
    } catch (err) {
      console.warn(err);
    }
  };

  useEvent('mouseup', handleClickOutside);
  useEvent('touchstart', handleClickOutside);

  return ref;
};
