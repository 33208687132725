import { useEffect, useReducer, useRef } from 'react';
import { toast } from 'react-toastify';
import { read, utils } from 'xlsx';
import { useQueryAPI } from '../../react-query';
import { htmlDecode, parseWorksheet } from './media-utils';
import { isAfter } from 'date-fns';
import { merge } from 'lodash';

const defaultMedia = {
  fileType: '',
  activeSheet: '',
  media: null,
  update: {
    type: '',
    modifiedAt: '',
  },
  hasError: false,
  fieldIdx: 0,
  maxFieldIdx: 0,
  lock: {},
  isParsingWorksheet: true,
  overlay: {
    display: false,
    message: '',
  },
  fieldHistory: {},
  latestFieldHistory: {},
  isComplete: false,
  showLargeFileDisclaimer: false,
  focusState: '',
};

const buildHistory = (state, action) => {
  const { data = {} } = action;
  const { fieldHistory = [], productId = '', mediaId = '' } = data;
  const history = fieldHistory || [];

  // Get latest field history by property_chain
  const historyByPropertyChain = history?.reduce((prev, item) => {
    const key = item?.product_id + item?.property_chain;
    if (item?.source_metadata?.ref_file_id === mediaId) {
      const lastCreatedAt = prev?.[key]?.created_at;
      const isCurrentMoreRecent = isAfter(new Date(item?.created_at), new Date(lastCreatedAt));

      if (!(key in prev) || ('created_at' in prev?.[key] && isCurrentMoreRecent)) {
        return {
          ...prev,
          [key]: item,
        };
      }
    }
    return prev;
  }, {});

  const historyByRef = Object.values(historyByPropertyChain)?.reduce((prev, item) => {
    if (item?.source_metadata?.ref_sheet !== state?.activeSheet) {
      return prev;
    }
    const ref = utils.encode_cell({
      c: item?.source_metadata?.ref_cell_col - 1,
      r: item?.source_metadata?.ref_cell_row - 1,
    });

    const isActiveProduct = prev?.[ref]?.isActiveProduct || item?.product_id === productId;

    return {
      ...prev,
      [ref]: {
        ...(prev?.[ref] || {}),
        isActiveProduct,
        recentPropertyChain: item?.property_chain,
        isMapped: true,
        list: [...(prev?.[ref]?.list || []), item],
      },
    };
  }, {});

  return historyByRef;
};

const mediaReducer = (nextState, action) => {
  const state = {
    ...nextState,
    update: {
      modifiedAt: new Date().toISOString(),
      type: action.type,
    },
  };

  switch (action.type) {
    case 'media-id': {
      return { ...state, mediaId: action.mediaId };
    }

    case 'refresh-sheet': {
      return { ...state, isParsingWorksheet: false };
    }

    case 'reset-product': {
      return {
        ...state,
        hasError: false,
        fieldIdx: 0,
        isComplete: false,
      };
    }
    case 'set-workbook': {
      let { workbook } = action?.payload;

      workbook.SheetNames = workbook.SheetNames?.map((item) => htmlDecode(item));
      workbook.Sheets = Object.entries(workbook?.Sheets || {})?.reduce((prev, [key, value]) => {
        const decodeKey = htmlDecode(key);
        return { ...prev, [decodeKey]: value };
      }, {});

      return {
        ...state,
        ...action.payload,
        workbook,
      };
    }
    case 'set-pdf': {
      let { pdf } = action?.payload;
      return {
        ...state,
        ...action.payload,
        workbook: pdf,
      };
    }

    case 'disable-activity': {
      return {
        ...state,
        disabled: true,
      };
    }

    case 'enable-activity': {
      return {
        ...state,
        disabled: false,
      };
    }

    case 'show-large-file-disclaimer': {
      return {
        ...state,
        showLargeFileDisclaimer: true,
      };
    }

    case 'set-worksheet': {
      return {
        ...state,
        worksheet: action.payload,
      };
    }

    case 'accept-input': {
      let fieldType = action?.payload?.fieldType;
      if (!action?.payload?.fieldType && action?.payload?.propertyChain.includes('Cost')) {
        fieldType = 'cost';
      }
      return {
        ...state,
        accept: {
          ...action.payload,
          fieldType,
          updatedAt: new Date().toISOString(),
        },
      };
    }

    case 'select-cell': {
      let { cell, cellRef, shouldSetNextFieldIdx } = action;
      if (!cell && cellRef && nextState.worksheet) {
        const position = utils.decode_cell(cellRef);
        const ref_cell_col = position?.c + 1;
        const ref_cell_row = position?.r + 1;
        cell = nextState.worksheet?.[ref_cell_row]?.[ref_cell_col];
      }
      const fieldIdx = Math.min(state.fieldIdx + 1, state.maxFieldIdx);
      const nextFieldIndex = shouldSetNextFieldIdx ? { fieldIdx } : {};
      const isComplete = shouldSetNextFieldIdx && state.fieldIdx + 1 > state.maxFieldIdx;

      return {
        ...state,
        ...nextFieldIndex,
        isComplete,
        selectedCell: cell,
        focus: {
          action: 'select',
          updatedAt: new Date().toISOString(),
          source: {},
          location: {
            type: 'xlsx',
            cellRef: cell.ref,
            sheet: state?.activeSheet,
            fileId: state?.mediaId,
          },
        },
        update: {
          ...state.update,
          ref: cell.ref,
          rowIdx: cell.rowIdx,
          lastFieldIdx: state.fieldIdx,
        },
      };
    }

    case 'select-text': {
      const { textSelection, shouldSetNextFieldIdx } = action;
      const fieldIdx = Math.min(state.fieldIdx + 1, state.maxFieldIdx);
      const setNextObject = shouldSetNextFieldIdx ? { fieldIdx } : {};
      const isComplete = shouldSetNextFieldIdx && state.fieldIdx + 1 > state.maxFieldIdx;
      return {
        ...state,
        ...setNextObject,
        isComplete,
        selectedText: textSelection,
        update: {
          ...state.update,
          ref: textSelection.location,
          lastFieldIdx: state.fieldIdx,
        },
      };
    }

    case 'set-complete': {
      const { value } = action;
      return { ...state, isComplete: value };
    }

    case 'set-field-index': {
      const { index } = action;
      const fieldIdx = Math.max(0, Math.min(index, state.maxFieldIdx));
      return { ...state, fieldIdx };
    }

    case 'set-max-field-index': {
      const { maxFieldIdx } = action;
      return {
        ...state,
        maxFieldIdx,
      };
    }

    case 'set-focus': {
      return {
        ...state,
        focus: {
          action: 'set',
          ...action?.focus,
          updatedAt: new Date().toISOString(),
        },
      };
    }

    case 'merge-focus': {
      const updatedFocus = merge({}, state?.focus, action?.focus);
      return {
        ...state,
        focus: {
          ...updatedFocus,
          updatedAt: new Date().toISOString(),
        },
      };
    }

    case 'set-active-sheet': {
      const { activeSheet } = action;
      return {
        ...state,
        activeSheet,
        isParsingWorksheet: !!activeSheet ? activeSheet !== state?.activeSheet : false,
        activeCellRef: '',
      };
    }

    case 'set-overlay': {
      const { value } = action;
      return {
        ...state,
        overlay: value,
      };
    }

    case 'parsing-workbook': {
      return {
        ...state,
        isParsingWorksheet: true,
      };
    }

    case 'parsing-pdf': {
      return {
        ...state,
        isParsingWorksheet: false,
      };
    }

    case 'parse-active-sheet': {
      try {
        const worksheet = parseWorksheet(state.workbook, state.activeSheet);
        return {
          ...state,
          worksheet,
          isParsingWorksheet: false,
          hasError: false,
        };
      } catch (err) {
        return {
          ...state,
          worksheet: {},
          isParsingWorksheet: false,
          hasError: state?.fileType === 'xlsx',
        };
      }
    }

    case 'reset-field-history': {
      return {
        ...state,
        fieldHistory: {},
      };
    }

    case 'set-field-history': {
      return {
        ...state,
        fieldHistory: buildHistory(state, action),
      };
    }

    case 'set-focus-state': {
      return {
        ...state,
        focusState: action?.value,
      };
    }

    case 'set-scroll-to-location': {
      const { scrollToLocation } = action;
      return {
        ...state,
        scrollToLocation,
      };
    }

    case 'set-search-results': {
      const { searchResults = [] } = action;
      return {
        ...state,
        searchResults,
      };
    }

    case 'set-current-property-chain': {
      return {
        ...state,
        currentPropertyChain: action?.property,
      };
    }

    default:
      return { ...state };
  }
};

export const useMedia = (mediaId, productId) => {
  const [state, dispatch] = useReducer(mediaReducer, defaultMedia);

  useEffect(() => {
    dispatch({ mediaId, type: 'media-id' });
  }, [mediaId]);

  const fieldHistoryUrl = encodeURI(
    `/v1/bp/product_field_history?order=created_at.desc&or=(source_metadata->>ref_file_id.eq.${mediaId},product_id.eq.${productId})`
  );
  const historyQuery = useQueryAPI({
    url: fieldHistoryUrl,
    cacheTime: 0,
    params: {
      axios: {
        headers: {
          'Accept-Profile': 'brite',
        },
      },
    },
  });

  const handleXlsx = async (event) => {
    try {
      var result = event.target.result;
      const workbook = read(result, {
        type: 'binary',
        cellStyles: true,
        skipHidden: true,
        blankrows: false,
        cellFormula: false,
        cellNF: true,
        cellHTML: false,
      });

      const activeSheet = workbook?.SheetNames?.[0];

      dispatch({
        type: 'set-workbook',
        payload: {
          fileType: 'xlsx',
          workbook,
        },
      });
      dispatch({
        type: 'set-active-sheet',
        activeSheet,
      });
    } catch (err) {
      console.log(err);
      toast.error(`Error parsing xlsx`);
    }
  };

  const handlePDF = async (event) => {
    try {
      // With pdfs, we just pass the buffer directly to react-pdf library, rather than reading it here.
      const uint8Array = new Uint8Array(event.target.result);
      dispatch({
        type: 'set-pdf',
        payload: {
          fileType: 'pdf',
          pdf: { data: uint8Array },
        },
      });
    } catch (err) {
      console.log('PDF ERROR: ', err);
      toast.error(`Error parsing pdf`);
    }
  };

  const handleMedia = async (data) => {
    let reader = new FileReader();
    reader.onerror = (err) => console.log(err);
    try {
      if (data.type === 'application/pdf') {
        reader.onload = handlePDF;
        reader.readAsArrayBuffer(data);
        dispatch({ type: 'parsing-pdf' });
      } else {
        reader.onload = handleXlsx;
        dispatch({ type: 'parsing-workbook' });
        reader.readAsBinaryString(data);
      }
    } catch (err) {
      toast.error(`Error parsing media.`);
    }
  };

  const mediaQuery = useQueryAPI({
    url: `v2/media/${mediaId}`,
    params: { axios: { responseType: 'blob' } },
    refetchOnWindowFocus: false,
    onSuccess: handleMedia,
    cacheTime: 0,
  });

  const timeoutRef = useRef();
  useEffect(() => {
    if (state?.isParsingWorksheet) {
      timeoutRef.current = setTimeout(() => {
        dispatch({ type: 'show-large-file-disclaimer' });
      }, 8000);
    } else {
      clearTimeout(timeoutRef.current);
    }
  }, [state?.isParsingWorksheet]);

  const isLoading = mediaQuery?.isLoading || state?.isParsingWorksheet || historyQuery?.isLoading;

  useEffect(() => {
    if (state?.activeSheet) {
      dispatch({ type: 'parse-active-sheet' });
    }
  }, [state?.activeSheet]);

  return {
    state: {
      ...state,
      mediaId,
    },
    dispatch,
    isLoading,
    historyQuery,
  };
};

// activeCellRef
// activeSheet
// scrollToCellRef
// scrollToLocation
// scrollToCellUpdatedAt

// set-latest-history
