import { forwardRef, useEffect } from 'react';
import { useThemedProps } from '../useThemedProps';
import { container, flex, scrollbar } from '../shared-styles';
import { colors } from '../styles';
import { textTheme } from './text';

const boxTheme = {
  radius: `border-radius: 8px;`,
  hover: container.hover,
  hoverOutline: container.hoverOutline,
  hideScrollbar: scrollbar.hide,
  scrollbar: scrollbar.style,
  padSm: `padding: 8px;`,
  padMd: `padding: 16px;`,
  padLg: `padding: 24px;`,
  padXl: `padding: 32px;`,
  page: `
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  `,
  option: `
    ${textTheme.label}
    ${flex('space-between')}
    border-radius: 8px;
    padding: 8px 16px;
    :hover {
      cursor: pointer;
      background-color: ${colors.gray[100]};
    }
    
  `,
  pill: `
    ${flex('space-between')}
    font-size: 16px;
    color: white;
    svg, p {
      font-weight: bold;
    }
    font-weight: bold;
    padding: 4px 16px;
    border-radius: 16px;
    background-color: ${colors.gray[100]};
    :hover {
      background-color: ${colors.gray[200]}
    }
    cursor: pointer;
  `,
  border: `
    border-radius: 8px;
    border: 1px solid ${colors.gray[300]};
  `,
  upload: `
    border-radius: 16px;
    border: 2px dashed ${colors.gray[300]};
    padding: 32px;
    height: 100px;
    ${flex('center column')}
    ${container.hover}
  `,
};

export const Box = forwardRef(({ children, onInitial = null, onCleanup = null, ...props }, ref) => {
  const themedProps = useThemedProps(props, boxTheme);
  useEffect(() => {
    if (onInitial) {
      onInitial();
    }
    return () => {
      if (onCleanup) {
        onCleanup();
      }
    };
  }, []);
  return (
    <div ref={ref} {...themedProps}>
      {children}
    </div>
  );
});
