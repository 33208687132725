import { useState, useEffect } from 'react';

import { colors } from '../shared/styles';
import { sortBy } from 'lodash';
import { GuideAnalytics } from './GuideAnalytics';

import { groupBy } from 'lodash';

import 'react-dates/lib/css/_datepicker.css';

import { CustomAxios } from '../redux/axios/axios';

import { createData, getDurationFromSeconds } from './helpers';

import ChartAnnotationsPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'chart.js';
import 'chartkick/chart.js';
import { useParams } from 'react-router-dom';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import Dates from '../shared/components/Dates';

Chart.register(ChartAnnotationsPlugin);

export const SharedAnalyticsPage = () => {
  const { courseId, businessId } = useParams();
  const [editEmployeeCount, setEditEmployeeCount] = useState(false);
  const [eligibleEmployee, setEligibleEmployee] = useState(0);
  const [pageDataRows, setPageDataRows] = useState([]);
  const [loadingPageViewsData, setLoadingPageViewsData] = useState(false);
  const [pageViewsStatistics, setPageViewsStatistics] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState({});
  const [selectedCourseData, setSelectedCourseData] = useState(null);
  const [endDate, setEndDate] = useState(endOfDay(new Date()));
  const [startDate, setStartDate] = useState(startOfDay(subDays(new Date(), 7)));
  const [timeFrame, setTimeFrame] = useState('Last 7 days');
  const [analytics, setAnalytics] = useState({
    Feedback: [],
    Buyups: [],
    Activity: [],
    Questions: [],
    Recommendations: [],
    Time: {
      TotalTime: 0,
    },
  });

  const fetchPageViewsStatistics = async (pages, id, start = startDate, end = endDate) => {
    setLoadingPageViewsData(true);
    // use CustomAxios to get page views statistics
    try {
      const engagementResponse = await CustomAxios.get(
        `/public/v1/business/${businessId}/analytics/engagement/${id}?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );

      if (!engagementResponse.data) {
        engagementResponse.data = {
          PageSessions: [],
          Questions: [],
          AveragePagesPerVisitor: 0,
          AverageTimePerVisitorSeconds: 0,
          TotalPageVisits: 0,
          TotalVisitTimeSeconds: 0,
        };
      }
      if (!engagementResponse.data.Questions) {
        engagementResponse.data.Questions = [];
      }

      let groupedByPages = groupBy(engagementResponse.data.PageSessions, function (page) {
        return page.PageID;
      });

      let largestPageVisits = 0;

      let pagesWithData = pages.map((page) => {
        let pageStats = engagementResponse.data.PageSessions.find((pageViewStats) => pageViewStats.PageID === page.ID);
        if (!pageStats) {
          pageStats = {};
        }
        pageStats.visits = groupedByPages[pageStats.PageID]?.length || 0;

        let pageSeshes = groupedByPages[pageStats.PageID] || [];
        let groupedByUser = groupBy(pageSeshes, function (sesh) {
          return sesh.UserID;
        });

        let totalSeconds = pageSeshes.reduce((n, { PageSessionDurationSeconds }) => n + PageSessionDurationSeconds, 0);
        let vis = pageStats.visits || 0;

        let averageSeconds = 0;
        if (vis > 0) {
          averageSeconds = totalSeconds / vis;
        }

        if (vis > largestPageVisits) {
          largestPageVisits = vis;
        }

        pageStats.unique = Object.keys(groupedByUser).length;
        pageStats.pageData = page;
        pageStats.average = getDurationFromSeconds(averageSeconds);
        pageStats.total = getDurationFromSeconds(totalSeconds);
        pageStats.totalSeconds = totalSeconds;
        pageStats.averageSeconds = averageSeconds;
        return pageStats;
      });

      const rows = [];
      const columnRows = [];

      for (let pageData of pagesWithData) {
        if (pageData.PageOrder) {
          rows.push(
            createData(
              pageData.PageOrder,
              pageData.pageData.Name,
              pageData.visits || 0,
              pageData.unique || 0,
              pageData.average,
              pageData.total
            )
          );
          const label = `${pageData?.PageOrder} - ${pageData?.pageData?.Name}`;
          columnRows.push([label, pageData.visits || 0]);
        }
      }

      pagesWithData.columnRows = columnRows;

      setPageDataRows(rows);
      setPageViewsStatistics({
        courseID: id,
        startDate: start,
        data: pagesWithData,
        engagementOverview: engagementResponse.data,
        largestPageVisits,
      });
    } catch (e) {
      console.log('error getting page view statistics', e);
    } finally {
      setLoadingPageViewsData(false);
    }
  };

  const getAnalytics = async (businessID, guideID, start = startDate, end = endDate) => {
    setLoading(true);
    try {
      const businessRes = await CustomAxios.get(`/public/v1/shared/business/${businessID}`);
      let selectedBusiness = businessRes.data;
      setSelectedBusiness(selectedBusiness);

      var response;
      let enrollmentConfidenceResponse;
      response = await CustomAxios.get(
        `/public/v1/business/${businessID}/analytics/traffic/${guideID}?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );

      enrollmentConfidenceResponse = { data: [], error: true };

      const feedbackResponse = await CustomAxios.get(
        `/public/v1/business/${businessID}/analytics/${guideID}/feedback?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );

      const feedbackCommentsResponse = await CustomAxios.get(
        `/public/v1/business/${businessID}/analytics/${guideID}/feedback/comments?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );
      let feedbackComments = feedbackCommentsResponse.data;
      let feedbackData = feedbackResponse.data;
      let data = response.data;
      if (!data.Activity) {
        data.Activity = [];
      }
      if (!data.Questions) {
        data.Questions = [];
      }

      if (!data.Recommendations) {
        data.Recommendations = [];
      }
      if (!data.Buyups) {
        data.Buyups = [];
      }

      let comments = [];

      if (feedbackComments) {
        comments = groupBy(feedbackComments, function (comment) {
          return comment.NpsNumber;
        });
      }
      let index = 0;

      if (!feedbackData) {
        data.Feedback = [];
      } else {
        let newFeedback = [];
        feedbackData = feedbackData.sort((a, b) => a.NpsNumber < b.NpsNumber);
        for (let i = 1; i < 6; i++) {
          if (!feedbackData[index] || i !== feedbackData[index].NpsNumber) {
            newFeedback.push({
              NpsNumber: i,
              Count: 0,
              Comments: comments[i] || [],
            });
          } else {
            newFeedback.push({
              ...feedbackData[index],
              Comments: comments[i] || [],
            });
            index = index + 1;
          }
        }

        let total = newFeedback.reduce((partialSum, b) => {
          return partialSum + b.Count;
        }, 0);

        for (let feedbackObj of newFeedback) {
          feedbackObj.percentage = Math.floor((feedbackObj.Count / total) * 100);
          feedbackObj.width = (feedbackObj.percentage / 100) * 600;
        }
        let colors = ['#25282D', '#36414C', '#66737F', '#9AA7B5', '#D1DAE3'];
        let colorIndex = 0;
        newFeedback = newFeedback.sort((a, b) => b.percentage - a.percentage);
        newFeedback[0].color = colors[colorIndex];

        if (newFeedback[0].percentage > newFeedback[1].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[1].color = colors[colorIndex];
        if (newFeedback[1].percentage > newFeedback[2].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[2].color = colors[colorIndex];
        if (newFeedback[2].percentage > newFeedback[3].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[3].color = colors[colorIndex];
        if (newFeedback[3].percentage > newFeedback[4].percentage) {
          colorIndex = colorIndex + 1;
        }
        newFeedback[4].color = colors[colorIndex];

        data.Feedback = newFeedback;
      }

      const courseRes = await CustomAxios.get(`/public/v1/shared/course/${guideID}`);
      let course = courseRes.data;
      if (course.Pages && course.Pages.length > 0) {
        course.Pages = sortBy(course.Pages, ['Order']);
      } else {
        course.Pages = [
          {
            Type: 'regular',
            Content: null,
            Name: 'Welcome',
            Order: 1,
            FrontendID: '_' + Math.random().toString(36).substr(2, 9),
            InsurancePlans: [],
            Question: null,
            IsLocked: false,
            TemplateID: '00000000-0000-0000-0000-000000000000',
          },
        ];
      }

      setSelectedCourseData(course);

      setEligibleEmployee(course.EligibleEmployees || selectedBusiness?.EmployeeCount || 0);
      fetchPageViewsStatistics(course.Pages, guideID, start, end);

      const conversionResponse = await CustomAxios.get(
        `/public/v1/business/${businessId}/analytics/conversion/${guideID}?startDate=${start.toISOString()}&endDate=${end.toISOString()}`
      );

      data.conversion = conversionResponse.data;
      if (!data.conversion.MedicalRecommendations) {
        data.conversion.MedicalRecommendations = [];
      }

      if (enrollmentConfidenceResponse) {
        data.enrollmentConfidence = enrollmentConfidenceResponse.data || [];
      }

      setAnalytics({ ...data });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAnalytics({
        Feedback: [],
        Buyups: [],
        Activity: [],
        Questions: [],
        Recommendations: [],
        Time: {
          TotalTime: 0,
        },
      });
    }
  };

  const countCommunicationByDay = () => {
    var groups = groupBy(analytics.GuideCommunications, function (LinkSent) {
      return format(new Date(LinkSent.created_at), 'yyyy-MM-dd');
    });

    // Return an array of objects by day with a CommunicationMedium, and total count for that day
    let obj = [];
    const keys = Object.keys(groups);
    for (const key of keys) {
      let emailCount = 0;
      let textCount = 0;
      let slackCount = 0;
      let teamsCount = 0;
      let pdfQRCodeCount = 0;

      for (const item of groups[key]) {
        if (item.communication_medium === 'email') {
          emailCount += 1;
        }
        if (item.communication_medium === 'text') {
          textCount += 1;
        }
        if (item.communication_medium === 'slack') {
          slackCount += 1;
        }
        if (item.communication_medium === 'teams') {
          teamsCount += 1;
        }
      }

      obj.push({
        date: key,
        emailCount,
        textCount,
        slackCount,
        teamsCount,
        pdfQRCodeCount,
      });
    }

    return obj;
  };

  let communicationAnnotations = {};
  let lineCount = 1;
  for (const item of countCommunicationByDay()) {
    let labelContent = ['Sent'];
    if (item.emailCount > 0) {
      labelContent.push(item.emailCount + ' emails');
    }
    if (item.textCount > 0) {
      labelContent.push(item.textCount + ' texts');
    }
    if (item.slackCount > 0) {
      labelContent.push(item.slackCount + ' slack messages');
    }
    if (item.teamsCount > 0) {
      labelContent.push(item.teamsCount + ' teams messages');
    }
    communicationAnnotations['line' + lineCount] = {
      type: 'line',
      xMin: item.date,
      xMax: item.date,
      borderColor: colors.purple,
      borderWidth: 2,
      borderDash: [8, 4],
      label: {
        content: labelContent,
        position: 'end',
      },
      enter({ element }, event) {
        element.label.options.display = true;
        return true; // force update
      },
      leave({ element }, event) {
        element.label.options.display = false;
        return true;
      },
    };
    lineCount++;
  }

  const refresh = (start, end) => {
    getAnalytics(businessId, courseId, start, end);
  };

  const setTimeframe = (timeframe) => {
    let days = 7;
    setTimeFrame(timeframe);
    if (timeframe === 'Last 30 days') {
      days = 30;
    }
    if (timeframe === 'Last 90 days') {
      days = 90;
    }
    if (timeframe === 'Last Year') {
      days = 365;
    }
    if (timeframe === 'Open Enrollment Dates') {
      if (selectedCourseData.TargetingEnd && selectedCourseData.TargetingStart) {
        const start = startOfDay(Dates.getDateFromISO(selectedCourseData.TargetingStart));
        setStartDate(start);
        const end = endOfDay(Dates.getDateFromISO(selectedCourseData.TargetingEnd));
        setEndDate(end);
        refresh(start, end);
      }
      return;
    }
    const start = startOfDay(subDays(new Date(), days));
    setStartDate(start);
    const end = endOfDay(new Date());
    setEndDate(end);
    refresh(start, end);
  };

  useEffect(() => {
    getAnalytics(businessId, courseId);
  }, [businessId, courseId]);

  return (
    <GuideAnalytics
      selectedBusiness={selectedBusiness}
      openEnrollmentCollection={null}
      setOpenEnrollmentCollection={() => {}}
      eligibleEmployee={eligibleEmployee}
      setEligibleEmployee={setEligibleEmployee}
      timeFrame={timeFrame}
      setTimeframe={setTimeframe}
      close={null}
      guide={selectedCourseData}
      setSelectedCourseData={setSelectedCourseData}
      startDate={startDate}
      endDate={endDate}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      focusedInput={focusedInput}
      setFocusedInput={setFocusedInput}
      refresh={refresh}
      loading={loading}
      coursesLoading={false}
      guideLoaded={true}
      analytics={analytics}
      editEmployeeCount={editEmployeeCount}
      setEditEmployeeCount={setEditEmployeeCount}
      communicationAnnotations={communicationAnnotations}
      pageViewsStatistics={pageViewsStatistics}
      loadingPageViewsData={loadingPageViewsData}
      pageDataRows={pageDataRows}
      updateEligibleEmployees={() => {}}
      updateOEDates={() => {}}
      guides={[]}
      selectCourse={null}
      forceV2={true}
    />
  );
};
