import { getGenericQueryProps, getUrl } from '.';

const url = '/v1/plansight/employers';

export const plansightEmployersResource = {
  url,
  key: 'plansight-employers',
  getResourceOptions: (resourceProps) => {
    const resourceUrl = getUrl(url, resourceProps);
    return {
      url: resourceUrl,
      queryProps: {
        initialData: { Data: [] },
        ...getGenericQueryProps(resourceUrl, resourceProps),
      },
    };
  },
  utils: {},
};
