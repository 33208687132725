import { css } from '@emotion/css';
import { Div, Text } from '../../shared/components';

export const EmptyState = () => {
  return (
    <Div
      css={css`
        margin: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <Div
        css={css`
          text-align: center;
          max-width: 300px;
          gap: ;
        `}
      >
        <Text
          h4
          css={css`
            margin-bottom: 16px;
          `}
        >
          No Data to Display
        </Text>
        <Text body>Try setting another date range or sharing your guide to get more analytics data.</Text>
      </Div>
    </Div>
  );
};

export const EmptyStateSmall = () => {
  return (
    <Div
      css={css`
        margin-top: 24px;
        margin-bottom: 24px;
      `}
    >
      <Text
        caption
        css={css`
          text-align: center;
        `}
      >
        No Data to Display
      </Text>
    </Div>
  );
};
