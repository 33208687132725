import { css } from '@emotion/css';
import { colors } from '../../shared/styles';

export const EditorGuideAnalyticsTabs = ({ tab, setTab, tabValues }) => {
  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        border-bottom: 1px solid ${colors.gray[300]};
        padding-bottom: 18px;
        width: 100%;
        margin-top: 32px;
        margin-bottom: 32px;
      `}
    >
      <div
        className={css`
          cursor: pointer;
          font-weight: 700;
          font-size: 18px;
          line-height: 175%;
          color: ${tab === tabValues.TRAFFIC_TAB ? colors.black : colors.gray[500]};
          border-bottom: ${tab === tabValues.TRAFFIC_TAB ? `4px solid ${colors.black}` : '4px solid transparent'};
          padding-bottom: 13px;
          margin-bottom: -19px;
          margin-right: 56px;
          &:hover {
            color: ${colors.black};
          }
        `}
        onClick={() => setTab(tabValues.TRAFFIC_TAB)}
      >
        Traffic
      </div>
      <div
        className={css`
          cursor: pointer;
          font-weight: 700;
          font-size: 18px;
          line-height: 175%;
          color: ${tab === tabValues.ENGAGEMENT_TAB ? colors.black : colors.gray[500]};
          border-bottom: ${tab === tabValues.ENGAGEMENT_TAB ? `4px solid ${colors.black}` : '4px solid transparent'};
          padding-bottom: 13px;
          margin-bottom: -19px;
          margin-right: 56px;
          &:hover {
            color: ${colors.black};
          }
        `}
        onClick={() => setTab(tabValues.ENGAGEMENT_TAB)}
      >
        Engagement
      </div>
      <div
        className={css`
          cursor: pointer;
          font-weight: 700;
          font-size: 18px;
          line-height: 175%;
          color: ${tab === tabValues.CONVERSION_TAB ? colors.black : colors.gray[500]};
          border-bottom: ${tab === tabValues.CONVERSION_TAB ? `4px solid ${colors.black}` : '4px solid transparent'};
          padding-bottom: 13px;
          margin-bottom: -19px;
          margin-right: 56px;
          &:hover {
            color: ${colors.black};
          }
        `}
        onClick={() => setTab(tabValues.CONVERSION_TAB)}
      >
        Conversion
      </div>
    </div>
  );
};
