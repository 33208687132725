import { CustomAxios } from '../redux/axios/axios';

export const resizeAndUpload = (file, max_width, max_height, imageEncoding, url, config, formData) => {
  var fileLoader = new FileReader(),
    imageObj = new Image();

  fileLoader.onload = function () {
    var data = this.result;
    imageObj.src = data;
  };

  fileLoader.readAsDataURL(file);

  // resizeResult is a promise because we need to wait for the data URI to read into the buffer which is async.
  const resizeResult = new Promise((resolve, reject) => {
    setTimeout(() => {
      // Give up after 5 seconds if we can't load the image.
      reject('Image took too long to load');
    }, 5000);

    imageObj.onload = function () {
      if (!file.type.match('image.*')) {
        reject('Not an image');
      }
      // Check for empty images
      if (this.width === 0 || this.height === 0) {
        reject('Image is empty');
      } else {
        var hRatio = max_width / this.width;
        var vRatio = max_height / this.height;
        var ratio = Math.min(hRatio, vRatio);

        //create a hidden canvas object we can use to create the new resized image data
        var canvas = document.createElement('canvas');
        canvas.id = 'hiddenCanvas';
        canvas.width = this.width * ratio;
        canvas.height = this.height * ratio;
        canvas.style.visibility = 'hidden';
        document.body.appendChild(canvas);

        let context = canvas.getContext('2d');
        context.drawImage(imageObj, 0, 0, this.width, this.height, 0, 0, this.width * ratio, this.height * ratio);

        var byteString = atob(canvas.toDataURL(imageEncoding).split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: file.type });
        const result = new File([blob], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        });
        formData.append('file', result);
        CustomAxios.post(url, formData, config)
          .then((response) => {
            resolve(response);
          })
          .catch((er) => {
            reject(er);
          });
      }
    };

    fileLoader.onabort = function () {
      reject('The upload was aborted.');
    };

    fileLoader.onerror = function () {
      reject('An error occured while reading the file.');
    };

    imageObj.onabort = function () {
      reject('Image load was aborted.');
    };

    imageObj.onerror = function () {
      reject('An error occured while loading image.');
    };
  });

  return resizeResult;
};
