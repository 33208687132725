import { css } from '@emotion/css';
import { Modal, Text } from '../../shared/components';
import { UploadSimple } from 'phosphor-react';
import { colors } from '../../shared/styles';
import { container, flex } from '../../shared/shared-styles';
import { useForm } from '../../shared/use-form';
import { BriteLoader } from '../../shared/components/brite-loader';
import { useUploadDoc } from './use-upload-doc';
import { useState } from 'react';
import { ReactComponent as AiFileGradient } from '../../images/svg/ai-file-gradient.svg';
import Plansight from '../../images/plansight-logo.png';
import Aca from '../../images/aca.png';
import { useStore } from '../../store-provider/use-store';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { history } from '../../history';
import { useParams } from 'react-router';

const selectionIcon = css`
  position: relative;
  ${flex('center')}
  padding: 0;
  width: 31px;
  height: 31px;
  border: 2px dashed ${colors.black};
  border-radius: 4px;
  margin-right: 16px;
  ::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 3px;
    background-color: ${colors.gray[200]};
    border-radius: 4px;
  }
`;

const uploadContainer = css`
  position: relative;
  border-radius: 8px;
  border: 2px dashed ${colors.purple};
  padding: 32px;
  min-height: 180px;
  ${flex('center column')}
  cursor: pointer;
  ${container.hover}
  * {
    pointer-events: none;
  }
`;

const itemContainer = css`
  position: relative;
  border-radius: 8px;
  padding: 16px 24px;
  background: white;
  border: 1px solid ${colors.gray[300]};
  margin-top: 16px;
  ${flex('left')}
  img, svg {
    margin-right: 16px;
    width: 32px;
  }
  :hover {
    border: 1px solid transparent;
  }
  ${container?.hoverOutline}
`;

export const DocumentToBP = ({ createPackage, ...modalProps }) => {
  const { businessId } = useParams();
  const store = useStore();
  const plansightImportFF = useFeatureFlagPayload('plansight-import');
  const benefitPkgForm = useForm({ Name: 'Package Name' }, [modalProps?.display]);
  const [loading, setLoading] = useState(false);
  const { getRootProps, getInputProps, isDragActive } = useUploadDoc({
    benefitPkg: benefitPkgForm?.values,
    setLoading,
  });

  const setupPackage = async (type) => {
    try {
      const response = await createPackage(type);
      if (type === 'scratch') {
        history.push(`/${businessId}/packages/${response?.data?.ID}`);
      } else if (type === 'plansight') {
        history.push(`/${businessId}/packages/${response?.data?.ID}?setup=plansight`);
      } else if (type === 'aca') {
        history.push(`/${businessId}/packages/${response?.data?.ID}/aca`);
      }
    } catch {}
  };

  return (
    <Modal {...modalProps}>
      <Modal.Paper width="600px">
        <Modal.Header onClose={modalProps.onClose} title="New Benefit Package" />
        <Modal.Body
          css={`
            position: relative;
            min-height: 200px;
          `}
        >
          {loading ? (
            <BriteLoader />
          ) : (
            <div className={uploadContainer} {...getRootProps()}>
              <input {...getInputProps()} />
              {!isDragActive ? (
                <>
                  <AiFileGradient />
                  <Text
                    label
                    bold
                    css={`
                      margin: 8px 0;
                    `}
                  >
                    Smart Import From Document
                  </Text>
                  <Text>Drag and drop or click to browse</Text>
                </>
              ) : (
                <UploadSimple size={64} />
              )}
            </div>
          )}
          {store?.data?.parentBusiness?.Metadata?.APIKeys?.plansight && plansightImportFF?.value ? (
            <div className={itemContainer} onClick={() => setupPackage('plansight')}>
              <img src={Plansight} alt="plansight-logo" />
              <Text label bold>
                Import From Plansight
              </Text>
            </div>
          ) : null}
          <div className={itemContainer} onClick={() => setupPackage('aca')}>
            <img src={Aca} alt="aca-logo" />
            <Text label bold>
              Import ACA Plan
            </Text>
          </div>
          <div className={itemContainer} onClick={() => setupPackage('scratch')}>
            <div className={selectionIcon} />
            <Text label bold>
              Start From Scratch
            </Text>
          </div>
        </Modal.Body>
      </Modal.Paper>
    </Modal>
  );
};
